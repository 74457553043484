<template>
  <div class="q-row flex items-center egs-traveller-counter">
    <q-btn
      :style="{ color: color }"
      icon="remove"
      outline
      round
      size="xs"
      @click="decrease"
    />
    <q-item-label :style="{ width: '40px' }" class="text-subtitle1 text-center">
      <input v-model="localValue" @input="validateInput" />
    </q-item-label>
    <q-btn
      :style="{ color: color }"
      icon="add"
      outline
      round
      size="xs"
      @click="increase"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { debounce } from 'quasar'

const props = defineProps({
  modelValue: { type: [Number, String], default: 0, required: true },
  color: { type: String, default: '#f5772f' },
  type: { type: String },
  min: { type: Number, default: 11 },
  max: { type: Number, default: 99 }
})

const emit = defineEmits(['update:modelValue'])
const localValue = ref(props.modelValue)

const increase = () => {
  if (localValue.value < props.max) {
    localValue.value = Number(localValue.value) + 1
    emit('update:modelValue', localValue.value)
  }
}

const validateInput = debounce(() => {
  let min = 0
  if (props.min > 0) min = props.min
  if (localValue.value < min) {
    localValue.value = min
  }
  if (localValue.value > Number(props.max)) {
    localValue.value = props.max
  }
  emit('update:modelValue', Number(localValue.value))
}, 1000)

const decrease = () => {
  let min = 0
  if (props.min > 0) min = props.min
  if (localValue.value > min) {
    localValue.value = Number(localValue.value) - 1
  }
  emit('update:modelValue', localValue.value)
}
</script>
<style lang="scss" scoped>
.egs-traveller-counter {
  gap: 6px;

  input {
    width: 40px;
    text-align: center;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 6px;
    font-size: 15px;
    font-weight: bold;
    color: gray;

    &:focus {
      outline: none;
    }
  }
}
</style>
