<template>
  <form-trip-builder
    v-if="payload"
    :key="render_key"
    :fields="meta.fields"
    :node_id="node.id"
    :style="{ '--q-primary': color }"
    @submit="submit"
  />
</template>
<script setup>
import { computed, defineEmits, ref } from 'vue'
import dayjs from 'dayjs'
import { stores } from 'src/stores'
import { onMounted } from 'vue'
import { get_package_query } from 'src/composables/utils/package-form'
import { get_meta_default } from './meta_default'

import FormTripBuilder from 'src/components/common/form-trip-builder/index.vue'

const props = defineProps({
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  node: { type: Object },
  setting: { type: Object }
})

const emit = defineEmits(['submit'])
const render_key = ref(0)
const package_store = stores.use_package()
const context_store = stores.use_context()
const payload = computed(() => package_store.trip_form[props.node?.id])

const traveler_limits = [
  {
    adult: {
      traveler_type: 'adult',
      limit: 9
    },
    child: {
      traveler_type: 'child',
      limit: 6
    },
    infant: {
      traveler_type: 'infant',
      limit: 6
    }
  }
]
const tour_package = {
  booking_cutoff_days: 6,
  total_traveler_limit: 9,
  traveler_limit_type: 'per_type'
}

const product = {
  traveler_types: ['adult', 'infant', 'child']
}

const place_types = computed(() => {
  return ['province_state', 'multi_city_vicinity', 'airport', 'city', 'country']
})

const rules = {
  dates: [(val) => !!val || 'Required'],
  place: [(val) => !!val || 'Required'],
  travelers: []
}

const meta = get_meta_default({
  props,
  rules,
  payload,
  traveler_limits,
  place_types: place_types.value,
  booking_cutoff_days: tour_package.booking_cutoff_days,
  traveler_limit_type: tour_package.traveler_limit_type,
  total_traveler_limit: tour_package.total_traveler_limit,
  default_traveler_count: tour_package.default_traveler_count,
  traveler_types: product.traveler_types,
  country_codes:
    context_store.country_codes ||
    (props.node.meta.country_codes &&
      Array.isArray(props.node.meta.country_codes) &&
      props.node.meta.country_codes.map((code) => code.code).join(','))
})

const submit = () => {
  const { travelers, place, dates } = payload.value || {}

  const expectation = {
    is_separate: false,
    mh_des_code: place?.id,
    mh_des_type: 'place_id',
    mh_start_date: dates
  }

  const data = {
    process: 'multileg_holiday',
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    travelers: JSON.stringify(travelers),
    expectation: JSON.stringify(expectation)
  }
  package_store.add_selected({
    data,
    key: `multileg_holiday-${expectation.mh_des_code}`,
    created_at: new Date().getTime(),
    payload: {
      travelers: travelers.length,
      dates: `${dayjs(dates[0]).format('ddd, MMM DD')} - ${dayjs(
        dates[1]
      ).format('ddd, MMM DD')}`,
      from: place?.name,
      min_date: dates[0]
    }
  })
  emit('submit', data)
}
onMounted(async () => {
  if (!package_store.trip_form[props.node.id]) {
    package_store.trip_form[props.node.id] = {
      place: null,
      dates: null,
      travelers: []
    }
  }
  if (props.default_destination && package_store.trip_form[props.node.id]) {
    package_store.trip_form[props.node.id].place = props.default_destination
  }
  await get_package_query(package_store, props.node.id)
  const default_values = props.setting?.default_values ?? {}
  if (default_values?.place || default_values?.place === null) {
    payload.value.place = default_values.place
  }
  if (default_values?.dates) {
    payload.value.dates = default_values.dates
  }
  if (default_values?.travelers) {
    payload.value.travelers = default_values.travelers
  }
  render_key.value += 1
})
</script>
