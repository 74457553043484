<template>
  <q-select
    option-label="name"
    option-value="code"
    :model-value="modelValue"
    :options="options"
    @filter="filterFn"
    @update:model-value="handle_changes"
    use-input
    input-debounce="0"
    :label="label"
    clearable
  >
    <template v-slot:prepend>
      <country-flag
        v-if="modelValue"
        :country_code="modelValue.code || value"
      />
      <q-icon v-else name="flag" />
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section side>
          <country-flag :country_code="scope.opt.code" />
        </q-item-section>
        <q-item-section>
          <q-item-label v-html="scope.opt.name" lines="1" />
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script setup>
import { defineEmits, ref } from 'vue'
import { COUNTRIES } from 'src/constants/countries'
import CountryFlag from 'src/components/common/country-flag'

const props = defineProps({
  modelValue: { type: Object },
  label: { type: String, required: true },
  rules: { type: Array },
  color: { type: String, default: '#1976d2' },
  label: { type: String }
})

const emit = defineEmits(['update:modelValue'])

const handle_changes = (value) => {
  emit('update:modelValue', value)
}
const country_options = COUNTRIES.map((country) => ({
  code: country[1],
  name: country[0]
}))
const options = ref(country_options)

const filterFn = (val, update) => {
  if (val === '') {
    update(() => {
      options.value = country_options
    })
    return
  }
  update(() => {
    const needle = val.toLowerCase()
    const res = country_options.filter(
      (v) =>
        v.name.toLowerCase().indexOf(needle) > -1 ||
        v.code.toLowerCase().indexOf(needle) > -1
    )
    options.value = res
  })
}
</script>
