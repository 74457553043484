const vCss = {
  beforeMount(el, binding) {
    applyCss(binding.value);
    applyCssToComponent(binding.value);
  },
  updated(el, binding) {
    applyCss(binding.value);
    applyCssToComponent(binding.value);
  }
};

function applyCss(css) {
  if (typeof css !== 'string') return;

  let styleTag = document.getElementById('v-css-style');
  if (!styleTag) {
    styleTag = document.createElement('style');
    styleTag.id = 'v-css-style';
    document.head.appendChild(styleTag);
  }

  if (!styleTag.innerHTML.includes(css)) {
    styleTag.innerHTML += css;
  }
}

function applyCssToComponent(css) {
  if (typeof css !== 'string') return;

  const component = document.querySelector('egs-cms');
  if (!component) {
    console.warn('Component <egs-cms> not found');
    return;
  }

  let styleTag = component.shadowRoot
    ? component.shadowRoot.querySelector('style')
    : component.querySelector('style');

  if (!styleTag) {
    styleTag = document.createElement('style');
    if (component.shadowRoot) {
      component.shadowRoot.appendChild(styleTag);
    } else {
      component.appendChild(styleTag);
    }
  }

  if (!styleTag.innerHTML.includes(css)) {
    styleTag.innerHTML += css;
  }
}

export default vCss;
