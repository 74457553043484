<template>
  <div v-if="modelValue && modelValue.length" class="col-12">
    <q-card bordered flat>
      <q-card-section class="q-py-sm bg-grey-2">
        <div
          class="text-subtitle2 text-weight-medium"
          dom-key="common.custom.duration"
        >
          {{ $t('common.custom.duration') }}
        </div>
      </q-card-section>
      <q-separator />
      <q-list separator>
        <q-item
          v-for="(city, index) in modelValue"
          :key="index"
          class="full-width"
        >
          <q-item-section>
            <q-item-label :lines="1" class="text-weight-medium">
              {{ city.place.name }}
              <span class="text-lowercase">
                {{ `(${$t('common.nights')})` }}
              </span>
            </q-item-label>
          </q-item-section>
          <q-item-section side>
            <div class="flex items-center">
              <q-btn
                class="q-mr-sm"
                color="primary"
                icon="remove"
                outline
                round
                size="xs"
                @click="() => changes(city.place.id, 'remove')"
              />
              <div class="text-center text-grey-10" style="width: 15px">
                {{ cities_by_id[city.place.id].days }}
              </div>
              <q-btn
                class="q-ml-sm"
                color="primary"
                icon="add"
                outline
                round
                size="xs"
                @click="() => changes(city.place.id, 'add')"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>
</template>
<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import key_by from 'lodash.keyby'
import clone_deep from 'lodash.clonedeep'

const props = defineProps({
  modelValue: { type: Array }
})

const emit = defineEmits(['update:modelValue'])
const cities_by_id = computed(() => key_by(props.modelValue, 'place.id'))
const cities_by_id_default = clone_deep(key_by(props.modelValue, 'place.id'))

const changes = (id, type) => {
  switch (type) {
    case 'add': {
      cities_by_id.value[id].days = cities_by_id.value[id].days + 1
      break
    }
    case 'remove': {
      const min = cities_by_id_default[id].days
      if (cities_by_id.value[id].days <= min) return
      cities_by_id.value[id].days = cities_by_id.value[id].days - 1
      break
    }
  }
}
</script>
