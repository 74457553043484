import { stores } from 'src/stores'
import { use_services } from 'src/composables/services'

export const authenticate = async ({ email, password }) => {
  const context_store = stores.use_context()
  const canvas_store = stores.use_canvas()
  const auth_store = stores.use_auth()
  const services = use_services()

  let payload = {
    email,
    password
  }

  payload.flow_type = 'Basic'
  payload.scope_type = 'Agent'
  if (canvas_store?.page?.group === 'B2C') {
    payload.scope_type = 'Customer'
  } 
  const service = services.AppService
  service.set_domain(context_store.app.booking_app_base_domain)
  const response = await service.authenticate(payload)

  if (response.status === 200) {
    auth_store.$patch((state) => {
      state.user.scope_id = response.data.scope_id
      state.user.scope_type = response.data.scope_type
      state.user.email = response.data.email
      state.user.access_token = response.data.access_token
      state.user.account_id = response.data.account_id

      const account = response.data.account

      const name = []
      if (account.first_name) name.push(account.first_name)
      if (account.last_name) name.push(account.last_name)
      if (name.length) {
        state.user.name = name.join(' ')
      }
    })
  }

  return response
}

export const create_simulated_user = () => {
  const context_store = stores.use_context()
  const auth_store = stores.use_auth()

  let result = {
    account_id: 'account_id',
    account_type: 'User',
    scope_id: 'scope_id',
    email: 'login.simulator@easygds.com',
    name: 'William Tunner',
    access_token:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODgyMjQxMzUsInJlZnJlc2hfdG9rZW4iOiJmMzMyMzM4YS0xYzgyLTQwZTYtOTI3NC1kYmVlNTE1NTQ3MTUiLCJhY2NvdW50X2lkIjoiMjJiZjkwNjUtYjQ3NC00YjhkLTkxMzktOGIyOWYwNzRlZTAxIiwiYWNjb3VudF90eXBlIjoiVXNlciIsImVtYWlsIjoiYWRtaW5AZ29xdW8uY29tIiwic2NvcGVfdHlwZSI6IlN5c3RlbSIsInNjb3BlX2lkIjpudWxsfQ.7SVhbxBeh5DbOz75aMHNo2PiejC0hu92Iz9J-YLdNgI'
  }
  if (context_store.template.type === 'B2B') {
    result.scope_type = 'Agent'
  } else {
    result.scope_type = 'Customer'
  }

  auth_store.user.scope_id = result.scope_id
  auth_store.user.scope_type = result.scope_type
  auth_store.user.email = result.email
  auth_store.user.name = result.name
  auth_store.user.access_token = result.access_token
  auth_store.user.account_id = result.account_id
}

export const logout_user = () => {
  const auth_store = stores.use_auth()

  auth_store.$patch((state) => {
    Object.keys(state.user).forEach((k) => {
      state.user[k] = null
    })
  })
}
