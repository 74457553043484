<template>
  <div v-if="destination" class="col-12">
    <div class="row q-col-gutter-md">
      <div class="col-12 q-pt-xs text-h6 text-weight-medium">
        <div class="row">
          <q-item-section>
            <q-item-label class="text-h6 text-weight-medium" lines="1">
              <span dom-key="components.products.multileg.list.holiday-in">
                {{
                  destination.name
                    ? $t('components.products.multileg.list.holiday-in')
                    : $t('components.products.multileg.list.trips', 'Trips')
                }}
              </span>
              {{ destination.name }}
            </q-item-label>
          </q-item-section>
        </div>
      </div>
      <div class="col-12 q-pt-xs">
        <div class="row">
          <q-item-section>
            <q-item-label v-if="total">
              <span class="text-capitalize">{{ $t('common.showing') }}</span>
              <span>{{ ` 1- ` }}</span>
              <span v-if="items_per_page < total">
                {{ `${items_per_page} ${$t('common.of')}` }}
              </span>
              <span>{{ ` ${total} ${$t('common.packages')}` }}</span>
            </q-item-label>
          </q-item-section>
        </div>
      </div>
      <div class="col-12 q-pt-xs">
        <q-separator />
      </div>
      <div class="col-12 q-pt-xs q-pb-md">
        <div class="row q-col-gutter-sm items-center">
          <div class="col-12 col-md-9">
            <div class="row full-width items-center">
              <div class="text-caption text-black col-md-9">
                <div dom-key="common.local-time-desc">
                  {{ $t('common.local-time-desc') }}
                </div>
                <div dom-key="common.taxes-and-fees-included">
                  {{ $t('common.taxes-and-fees-included') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="total">
      <div :class="['row', `q-col-gutter-${meta.items_gap ?? 'md'}`]">
        <div
          v-for="(record, i) in records.slice(0, items_per_page)"
          :key="i"
          :class="get_class"
          :style="record.search_ranking ? 'height: 100%' : ''"
        >
          <item
            :language_code="language_code"
            :value="record"
            @select="on_select"
          />
        </div>
        <div v-if="items_per_page < total" class="col-12 text-center">
          <q-btn
            class="text-capitalize"
            color="primary"
            icon-right="arrow_drop_down"
            no-caps
            outline
            rounded
            @click="load_more"
          >
            <span dom-key="more-options">
              {{ $t('common.more-options') }}
            </span>
          </q-btn>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="processes.listing.in_progress">
        <div class="row q-col-gutter-md">
          <div v-for="n in 5" :key="n" :class="$is_mobile ? 'col-12' : 'col-6'">
            <skeleton-item />
          </div>
        </div>
      </template>
      <template v-else>
        <q-item class="bg-orange-2 eg-rounded">
          <q-item-section avatar>
            <q-icon color="orange" name="report" size="lg" />
          </q-item-section>
          <q-item-section>
            <q-item-label class="text-weight-medium">
              <div dom-key="components.products.multileg.list.no-data.title">
                {{ $t('components.products.multileg.list.no-data.title') }}
              </div>
            </q-item-label>
            <q-item-label caption>
              <div dom-key="components.products.multileg.list.no-data.caption">
                {{ $t('components.products.multileg.list.no-data.caption') }}
              </div>
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </template>
  </div>
  <div v-else>
    <q-item class="bg-orange-2 eg-rounded">
      <q-item-section avatar>
        <q-icon color="orange" name="report" size="lg" />
      </q-item-section>
      <q-item-section>
        <q-item-label class="text-weight-medium">
          <div dom-key="components.products.multileg.list.no-data.title">
            {{ $t('components.products.multileg.list.no-data.title') }}
          </div>
        </q-item-label>
        <q-item-label caption>
          <div dom-key="components.products.multileg.list.no-data.caption">
            {{ $t('components.products.multileg.list.no-data.caption') }}
          </div>
        </q-item-label>
      </q-item-section>
    </q-item>
  </div>
  <q-dialog v-model="dialog_search">
    <q-card style="width: 500px; max-width: 500px">
      <q-item>
        <q-item-section>
          <q-item-label
            class="text-h6 text-capitalize"
            dom-key="common.holiday-question-dialog"
          >
            {{ $t('common.holiday-question-dialog') }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn dense flat icon="close" round @click="dialog_search = false" />
        </q-item-section>
      </q-item>
      <q-separator />
      <q-card-section>
        <your-trips
          :mh_code="destination?.code"
          :mh_id="destination?.id"
          :trip="trip_selected"
          @submit="on_submit"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { use_services } from 'src/composables/services'
import { stores } from 'src/stores'

import Item from 'src/components/common/multileg-holiday/item'
import SkeletonItem from 'src/components/common/skeleton-item'
import YourTrips from 'src/components/common/multileg-holiday/dialog'

const props = defineProps({
  meta: { type: Object, required: true },
  destination: { type: Object, required: true }
})

const context_store = stores.use_context()
const auth_store = stores.use_auth()

const language_code = context_store.language?.code
const currency_code = context_store.currency?.code

const get_class = computed(() => {
  return `col-${12 / props.meta.columns[context_store.viewport] ?? '6'}`
})

const processes = reactive({
  listing: { in_progress: false },
  filtering: { in_progress: false },
  loading_more: { in_progress: false }
})

const trip_selected = ref(null)
const dialog_search = ref(false)
const records = ref([])
const total = ref(null)
const services = use_services()
const $is_mobile = false

const items_per_page = ref(
  props.meta.items_per_page[context_store.viewport] ?? 3
)

const load_more = () => {
  if (Number(items_per_page.value) >= Number(total.value)) return
  items_per_page.value =
    Number(items_per_page.value) +
    Number(props.meta.items_per_page[context_store.viewport] ?? 3)
}

const on_select = (item) => {
  trip_selected.value = item
  dialog_search.value = true
}

const on_submit = (data) => {
  if (!data) return
  if (auth_store.user.access_token) {
    data.access_token = auth_store.user.access_token
  }
  const booking_app_base_domain = context_store.app.booking_app_base_domain
  if (!booking_app_base_domain) return
  const qs = new URLSearchParams(data).toString()
  const url = `https://${booking_app_base_domain}/shopping/products/multileg-holiday/${trip_selected.value.id}?${qs}`
  console.info('trips url', url)
  location.href = url
}

watch(
  () => props.meta.trips,
  (trips) => {
    records.value = trips
    total.value = trips?.length ?? 1
  },
  { deep: true }
)

onMounted(async () => {
  if (!props.meta.trips?.length)  {
    processes.listing.in_progress = true
    const response = await services.AppService.fetch_trips({
      place_id: props.destination?.id,
      currency: currency_code
    })
    records.value = response.data.results
    total.value = response.data.total
    processes.listing.in_progress = false
  } else {
    records.value = props.meta.trips
    total.value = props.meta.trips?.length ?? 1
  }
})
</script>
