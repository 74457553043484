<template>
  <form-builder
    product_name="bundle"
    v-if="payload"
    @submit="submit"
    :key="update_key"
    :node_id="node.id"
    :fields="
      design === EXPEDIA_PACKAGE_FORM_DESIGN ? meta_expedia.fields : meta.fields
    "
  />
</template>
<script setup>
import { computed, defineEmits, onMounted, ref } from 'vue'
import { EXPEDIA_PACKAGE_FORM_DESIGN } from 'src/constants'
import keyBy from 'lodash.keyby'
import cloneDeep from 'lodash.clonedeep'
import { storeToRefs } from 'pinia'
import { stores } from 'src/stores'
import dayjs from 'dayjs'

import FormBuilder from 'src/components/common/form-builder'
import { get_meta_default } from './meta_default'
import { get_meta_expedia } from './meta_expedia'

const props = defineProps({
  bundle: { type: Object, required: true },
  product: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  design: { type: String },
  node: { type: Object },
  setting: { type: Object },
  place_types: { type: Array, default: () => [] }
})

const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(
  () => package_store.payload[props.node?.id]?.['bundle']
)
const { date_format } = storeToRefs(context_store)

const traveler_limits = keyBy(props.bundle.traveler_limits, 'traveler_type')
const emit = defineEmits(['submit'])
const update_key = ref(0)

onMounted(() => {
  if (props.default_destination && payload.value) {
    payload.value.ar_airport = props.default_destination
  }
  const default_values = props.setting?.default_values ?? {}

  if (default_values?.dp_airport || default_values?.dp_airport === null) {
    payload.value.dp_airport = default_values.dp_airport
  }
  if (default_values?.ar_airport || default_values?.ar_airport === null) {
    payload.value.ar_airport = default_values.ar_airport
  }
  if (default_values?.flight_dates) {
    payload.value.flight_dates = default_values.flight_dates
  }
  if (default_values?.travelers) {
    payload.value.travelers = default_values.travelers
  }
  if (default_values?.cabin_class) {
    payload.value.cabin_class = default_values.cabin_class
  }
  if (default_values?.stars) {
    payload.value.stars = default_values.stars
  }
  if (
    payload.value.form_data &&
    default_values?.form_data?.separate !== undefined
  ) {
    payload.value.form_data.separate = default_values?.form_data?.separate
  }
  if (default_values?.hotel_dates) {
    payload.value.hotel_dates = default_values.hotel_dates
  }
  update_key.value += 1
})

const rules = {
  dates: [(val) => !!val || ''],
  dp_airport: [
    (val) => !!val || '',
    (v) => validate_duplicate_airport(v, 'dp_airport') || ''
  ],
  ar_airport: [
    (val) => !!val || '',
    (v) => validate_duplicate_airport(v, 'ar_airport') || ''
  ],
  cabin_class: [(val) => !!val || ''],
  travelers: []
}

const validate_duplicate_airport = (value, type) => {
  if (type === 'ar_airport') {
    return value.id !== payload.value?.dp_airport?.id
  }
  if (type === 'dp_airport') {
    return value.id !== payload.value?.ar_airport?.id
  }
}

const handle_swap_airport = () => {
  const origin = cloneDeep(payload.value.dp_airport)
  const destination = cloneDeep(payload.value.ar_airport)
  payload.value.ar_airport = origin
  payload.value.dp_airport = destination
}

const buildExpectCodeAndType = (data) => {
  const { type, code, id } = data || {}
  let resultCode, resultType
  if (type === 'airport') {
    resultCode = code
    resultType = 'airport_code'
  } else if (
    [
      'city',
      'multi_city_vicinity',
      'province_state',
      'administrative_area_level_4'
    ].includes(type)
  ) {
    resultCode = code
    resultType = 'city_code'
  } else {
    resultCode = id
    resultType = 'place_id'
  }
  return [resultCode, resultType]
}

const validate_airport = (place, type) => {
  const dp_airport_id = payload.value.dp_airport?.id ?? ''
  const ar_airport_id = payload.value.ar_airport?.id ?? ''
  if (type === 'dp_airport' && ar_airport_id === place.id) {
    return false
  }
  if (type === 'ar_airport' && dp_airport_id === place.id) {
    return false
  }
  return true
}

const meta_expedia = get_meta_expedia({
  props,
  rules,
  place_types: props.place_types,
  traveler_limits,
  payload,
  date_format: date_format.value,
  handle_swap_airport,
  validate_airport
})

const meta = get_meta_default({
  props,
  rules,
  traveler_limits,
  payload,
  date_format: date_format.value,
  validate_airport
})

const submit = () => {
  const {
    travelers,
    cabin_class,
    flight_dates,
    dp_airport,
    ar_airport,
    hotel_dates,
    stars,
    form_data
  } = payload.value || {}

  // let process = 'bundle'

  // if (props.bundle.bundled) {
  //   process = 'bundle'
  // }

  let [start_place_code, start_place_type] = buildExpectCodeAndType(dp_airport),
    [des_code, des_type] = buildExpectCodeAndType(ar_airport)

  const expectation = {
    fl_cabin_class: cabin_class,
    fl_departure_date: flight_dates[0],
    fl_return_date: flight_dates[1],
    fl_round_trip: true,
    start_place_code,
    start_place_type,
    des_code: ar_airport.code,
    des_type,
    ht_des_code:
      ar_airport.type === 'airport' ? ar_airport.code : ar_airport.id,
    ht_des_type: ar_airport.type === 'airport' ? 'airport_code' : 'place_id',
    ht_checkin_date: hotel_dates?.[0] || flight_dates[0],
    ht_checkout_date: hotel_dates?.[1] || flight_dates[1],
    is_separate: form_data.separate,
    stars: stars
  }

  const data = {
    process: 'bundle',
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    package_id: props.bundle.id,
    travelers: JSON.stringify(travelers),
    is_separate: form_data.separate,
    expectation: JSON.stringify(expectation)
  }
  if (props.flight_campaign) {
    data.flight_campaign = props.flight_campaign
  }
  if (props.partner_id) {
    data.partner_id = props.flight_campaign
  }
  package_store.add_selected({
    data,
    key: `bundle-${expectation.start_place_code}-${expectation.des_code}`,
    created_at: new Date().getTime(),
    payload: {
      dates: flight_dates.map(x=> dayjs(x).format('ddd, MMM DD')).join(' - '),
      min_date: flight_dates[0],
      from: dp_airport.name,
      to: ar_airport.name,
      travelers: travelers.length
    }
  })
  emit('submit', data)
}
</script>
