<template>
  <div class="row items-center">
    <div class="text-right q-mr-sm">
      <q-item-label class="text-capitalize text-black">
        {{ $t(judgement) }}
      </q-item-label>
      <q-item-label caption>
        <span
          style="border-bottom: 1px dotted"
          dom-key="common.reviews"
        >
          {{count}} {{$t('common.reviews')}}
        </span>
      </q-item-label>
    </div>
    <div class="eg-bundle-review flex items-center justify-center bg-primary text-white">
      <q-item-label class="text-h6 text-weight-regular">
        {{ score }}
      </q-item-label>
    </div>
    <q-menu v-if="hotel.tripadvisor_id">
      <iframe
          :src="tripadvisor_url"/>
    </q-menu>
  </div>
</template>
<script>

export default {
  props: {
    average: {type: Number, default: 0},
    count: {type: Number, default: 0},
    hotel: {type: Object, required: true},
  },
  data () {
    return {
      showed: false
    }
  },
  computed: {
    tripadvisor_url () {
      return `https://www.tripadvisor.com/WidgetEmbed-cdspropertysummary?locationId=${this.hotel.tripadvisor_id}&partnerId=3021DF76804F4FD8909FA153F5133AE9&display=true`
    },
    score () {
      return Number(this.average).toFixed(1)
    },
    judgement () {
      if (+this.average >= 4.5) return 'common.excellent'
      if (+this.average >= 3.5) return 'common.very-good'
      if (+this.average >= 2.5) return 'common.good'
      return 'common.poor'
    }
  },
  methods: {
    open () {
      this.showed = true
    },
    close () {
      this.showed = false
    }
  }
}
</script>

<style lang="scss">
  .eg-bundle-review {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border-bottom-left-radius: 0;
  }
</style>
