import {stores} from 'src/stores'
import {
  ADDING_ELEMENT_NODE_TYPE,
  ADDING_LAYOUT_NODE_TYPE,
  EDIT_MODE,
  EDITOR_EVENT_CREATED_STATUS,
  EDITOR_EVENT_FAILED_STATUS,
  EDITOR_EVENT_PROCESSING_STATUS,
  ELEMENT_TOOL_COLOR,
  FOOTER_CODE,
  FOOTER_TOOL_COLOR,
  HEADER_CODE,
  HEADER_TOOL_COLOR,
  HORIZONTAL_AXIS_VECTOR,
  LAYOUT_TYPE,
  MAX_EDIT_HISTORY,
  NODE_BOTTOM_SIDE,
  NODE_CENTER,
  NODE_EDGES,
  NODE_KINDS,
  NODE_LEFT_SIDE,
  NODE_RIGHT_SIDE,
  NODE_SIDES,
  NODE_TOP_SIDE,
  SECTION_CODE,
  SECTION_TOOL_COLOR,
  TAB_NODE_CODE,
  VERTICAL_AXIS_VECTOR,
  CAROUSE_NODE_CODE,
  EDITOR_BULK_COMMAND_CREATED_STATUS,
  EDITOR_BULK_COMMAND_PROCESSING_STATUS,
  EDITOR_BULK_COMMAND_PROCESSED_STATUS,
  EDITOR_EVENT_MOVE_NODE_CODE,
  EDITOR_EVENT_ADD_NODE_CODE,
  EDITOR_EVENT_DELETE_NODE_CODE,
  EDITOR_EVENT_UPDATE_NODE_CODE,
  EDITOR_EVENT_PROCESSED_STATUS,
  EDITOR_EVENT_CHANGE_NODE_POSITION_CODE
} from 'src/constants'
import {nextTick} from 'vue'
import cloneDeep from 'lodash.clonedeep'
import {
  flatten_nodes,
  gen_uuid4,
  get_node_children,
  get_node_descendants,
  nest_nodes
} from 'src/composables/utils'
import {
  get_dom_node,
  get_node_el,
  get_node_layout,
  get_node_rect_on_canvas,
  is_node_visible,
  refresh_visible_nodes
} from 'src/composables/canvas'
import {useQuasar} from 'quasar'
import {use_services} from 'src/composables/services'
import is_empty from 'lodash.isempty'

export const close_left_expanded_menu = () => {
  const editor_store = stores.use_editor()

  editor_store.left_menu.expanded = false

  end_adding_layouts()
  end_setting_page()
  end_adding_elements()
}


export const revert_from_history = async (index) => {
  const canvas_store = stores.use_canvas()
  const editor_store = stores.use_editor()
  const context_store = stores.use_context()

  if (!editor_store.history[index]) return
  const data = JSON.parse(editor_store.history[index])
  editor_store.reverting_from_history.in_progress = true

  canvas_store.$patch((state) => {
    state.nodes = data.nodes
    state.pages = data.pages
    state.page = data.pages.find((p) => p.id === state.page.id)
  })
  context_store.$patch((state) => {
    state.template = data.template
  })

  await nextTick()

  refresh_visible_nodes()
  refresh_node_orders()
  refresh_focus_boxes()

  await nextTick()

  editor_store.reverting_from_history.in_progress = false
  editor_store.reverting_from_history.index = index
}

export const trigger_media_uploader = (meta) => {
  const file_store = stores.use_file()

  file_store.uploading.triggering_uploader = true
  file_store.uploading.triggering_uploader_meta = meta || {}
}

export const start_setting_page = () => {
  const editor_store = stores.use_editor()
  editor_store.setting_page.value = true
}

export const end_setting_page = () => {
  const editor_store = stores.use_editor()
  editor_store.setting_page.value = false
}

export const reset_focus_boxes = () => {
  const editor_store = stores.use_editor()
  editor_store.$patch((state) => {
    state.selected_layout_node = null
    state.selected_node = null
    state.hovered_node = null
    state.hovered_layout_node = null
  })
}

export const change_mode = (mode) => {
  const context_store = stores.use_context()
  if (mode !== EDIT_MODE) {
    end_adding_layouts()
    end_setting_page()
    end_editing_texts()
    end_dragging()
    reset_focus_boxes()
  }
  context_store.mode = mode
}

export const get_node_rect = (node_id) => {
  const dom_node = get_dom_node(node_id)
  if (!dom_node) return null
  return get_rect_from_dom_el(dom_node)
}

export const get_rect_from_dom_el = (dom_el) => {
  const editor_store = stores.use_editor()

  let result = {
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  }

  const mc_rect = editor_store.mouse_catcher.el.getBoundingClientRect()
  const dom_el_rect = dom_el.getBoundingClientRect()

  // node's coordinates relatively with canvas
  result.width = dom_el_rect.width
  result.height = dom_el_rect.height
  result.x = dom_el_rect.left - mc_rect.left
  result.y = dom_el_rect.top - mc_rect.top

  // for node sides
  result.top = result.y
  result.left = result.x
  result.right = result.x + result.width
  result.bottom = result.y + result.height

  return result
}

export const get_theme_color = (node_id) => {
  const canvas_store = stores.use_canvas()
  const node = canvas_store.flattened_nodes[node_id]
  if ([SECTION_CODE].includes(node.code)) {
    return SECTION_TOOL_COLOR
  }
  if (node.code === HEADER_CODE) {
    return HEADER_TOOL_COLOR
  }
  if (node.code === FOOTER_CODE) {
    return FOOTER_TOOL_COLOR
  }
  return ELEMENT_TOOL_COLOR
}

export const add_node = async ({
                                 type,
                                 node,
                                 destination_id = null,
                                 autofocus = true
                               }) => {
  const canvas_store = stores.use_canvas()
  const editor_store = stores.use_editor()

  if (type === ADDING_ELEMENT_NODE_TYPE) {
    if (!destination_id) {
      throw new Error('Missing destination_id for element type nodes')
    }
  }

  const children = node?.children
  if (children) delete node.children

  if (type === ADDING_LAYOUT_NODE_TYPE) {
    node.page_id = canvas_store.page.id

    // slide other nodes aside
    canvas_store.nested_nodes
      .filter(
        (n) =>
          n.type === LAYOUT_TYPE &&
          n.order >= node.order && n.id !== node.id
      )
      .forEach((n) => {
        canvas_store.$patch(state => {
          state.nodes[n.id].order++
        })
      })
  }
  else {
    const destination = canvas_store.nodes[destination_id]

    let layout_node

    if (destination.type === LAYOUT_TYPE) {
      layout_node = destination
    }
    else {
      layout_node = get_node_layout(destination.id)
    }

    if ([HEADER_CODE, FOOTER_CODE].includes(layout_node.code)) {
      delete node.page_id
      canvas_store.layout_nodes[node.id] = node
    }
    else {
      node.page_id = canvas_store.page.id
    }

    node.parent_id = destination_id

    let slipped_aside_nodes = get_node_children(
      destination_id,
      Object.values(canvas_store.nodes)
    )

    if (destination.code === TAB_NODE_CODE) {
      if (!node.meta.tab_id) node.meta.tab_id = destination.meta.tab_id
      slipped_aside_nodes = slipped_aside_nodes.filter(
        (n) => n.meta.tab_id === destination.meta.tab_id
      )
    }

    if (destination.code === CAROUSE_NODE_CODE) {
      if (!node.meta.slide_id) node.meta.slide_id = destination.meta.slide_id
      slipped_aside_nodes = slipped_aside_nodes.filter(
        (n) => n.meta.slide_id === destination.meta.slide_id
      )
    }

    slipped_aside_nodes.forEach((n) => {
      canvas_store.nodes[n.id].order++
    })
  }

  canvas_store.nodes[node.id] = node

  // bulk commands for creating node
  create_bulk_command({
    object_type: 'Node',
    object_id: node.id,
    code: 'Create',
    data: node
  })

  if (children) {
    children.forEach(cn => {
      add_node({
        type: ADDING_ELEMENT_NODE_TYPE,
        node: cn,
        destination_id: node.id,
        autofocus: false
      })
    })
  }

  refresh_node_parent_ids(node.id)

  if (autofocus) {
    await nextTick()

    editor_store.$patch((state) => {
      if (node.type === LAYOUT_TYPE) {
        state.selected_node = null
        state.selected_layout_node = node
      }
      else {
        state.selected_node = node
        state.selected_layout_node = get_node_layout(node.id)
      }
    })
    if (window) {
      const focus_node_el = get_dom_node(node.id)
      if (focus_node_el) {
        const focus_node_rect = focus_node_el.getBoundingClientRect()
        canvas_store.el.scrollTo({
          top: focus_node_rect.top,
          behavior: 'smooth'
        })
      }
    }
  }
}

export const move_node = async ({node_id, destination_id, autofocus = true}) => {
  const canvas_store = stores.use_canvas()
  const editor_store = stores.use_editor()

  const node = canvas_store.nodes[node_id]
  const destination = canvas_store.nodes[destination_id]

  const layout_of_node = get_node_layout(node.id)
  const layout_of_destination = get_node_layout(destination.id)

  if ([HEADER_CODE, FOOTER_CODE].includes(layout_of_node.code)) {
    if (![HEADER_CODE, FOOTER_CODE].includes(layout_of_destination.code)) {
      node.page_id = canvas_store.page.id
      // remove node and its descendants from layout_nodes
      canvas_store.$patch((state) => {
        delete state.layout_nodes[node.id]
        get_node_descendants(node.id).forEach((n) => {
          delete state.layout_nodes[n.id]
        })
      })
    }
  }
  else {
    if ([HEADER_CODE, FOOTER_CODE].includes(layout_of_destination.code)) {
      node.page_id = null
      // add node and its descendants to layout_nodes
      canvas_store.$patch((state) => {
        state.layout_nodes[node.id] = node
        get_node_descendants(node.id).forEach((n) => {
          state.layout_nodes[n.id] = n
        })
      })
    }
  }

  node.parent_id = destination.id

  if (destination.code === TAB_NODE_CODE) {
    node.meta.tab_id = destination.meta.tab_id
  }

  const neighbor_nodes = get_node_children(
    destination.id,
    Object.values(canvas_store.nodes)
  )
  node.order = neighbor_nodes.length

  canvas_store.$patch((state) => {
    state.nodes[node.id] = node
  })

  refresh_node_parent_ids(node.id)

  // Also refresh parent_ids of its descendants
  get_node_descendants(node.id, Object.values(canvas_store.nodes)).forEach(n => {
    refresh_node_parent_ids(n.id)
  })

  if (autofocus) {
    await nextTick()
    editor_store.$patch((state) => {
      if (node.type === LAYOUT_TYPE) {
        state.selected_node = null
        state.selected_layout_node = node
      }
      else {
        state.selected_node = node
        state.selected_layout_node = get_node_layout(node.id)
      }
    })
    if (window) {
      const focus_node_el = get_dom_node(node.id)
      if (focus_node_el) {
        const focus_node_rect = focus_node_el.getBoundingClientRect()
        canvas_store.el.scrollTo({
          top: focus_node_rect.top,
          behavior: 'smooth'
        })
      }
    }
  }
}

export const start_adding_layouts = () => {
  const editor_store = stores.use_editor()
  editor_store.adding_layouts.value = true
}

export const end_adding_layouts = () => {
  const editor_store = stores.use_editor()

  editor_store.adding_layouts.value = false
  editor_store.adding_layouts.block = null
  editor_store.adding_layouts.nodes = []
}

export const start_adding_elements = () => {
  const editor_store = stores.use_editor()
  editor_store.adding_elements.value = true
}

export const end_adding_elements = () => {
  const editor_store = stores.use_editor()
  editor_store.adding_elements.value = false
  editor_store.adding_elements.block = null
  editor_store.adding_elements.nodes = []
}

export const calculate_dragging_limits = () => {
  const editor_store = stores.use_editor()
  const canvas_store = stores.use_canvas()

  const limits = {
    top: null,
    left: null,
    right: null,
    bottom: null
  }
  const targeted_node = canvas_store.nodes[editor_store.dragging.node.id]

  if (targeted_node.parent_id) {
    const parent_node = canvas_store.nodes[targeted_node.parent_id]
    const parent_rect = get_node_rect_on_canvas(parent_node.id)
    const allow_to_leave = !parent_node.relation?.undetachable_kinds?.includes(
      targeted_node.code
    )
    if (allow_to_leave === false) {
      const parent_edges = {
        top: parent_rect.y,
        left: parent_rect.x,
        // use the opposite value for easier comparing
        right: -(parent_rect.width + parent_rect.x),
        bottom: -(parent_rect.height + parent_rect.y)
      }
      Object.keys(limits).forEach((k) => {
        if (limits[k] === null) limits[k] = parent_edges[k]
        else limits[k] = Math.max(limits[k], parent_edges[k])
      })
    }
  }
  return limits
}

export const start_editing_texts = (node_id) => {
  const editor_store = stores.use_editor()
  const canvas_store = stores.use_canvas()
  editor_store.editing_texts.value = true
  editor_store.editing_texts.node = canvas_store.nodes[node_id]
}

export const end_editing_texts = () => {
  const editor_store = stores.use_editor()

  editor_store.editing_texts.value = false
  editor_store.editing_texts.node = null
}

export const generate_node_grid_line_id = ({node, node_side, axis, x, y}) => {
  let result = ['node-vector', node.id, node_side, axis]
  if (!x) x = 0
  if (!y) y = 0
  result.push(x + y)
  return result.join('|')
}

export const create_node_grid_lines = (nodes, related_node) => {
  const canvas_store = stores.use_canvas()
  const editor_store = stores.use_editor()

  const result = []

  nodes.forEach((node) => {
    const node_rect = get_node_rect(node.id)
    Object.keys(NODE_SIDES).forEach((ns) => {
      const line_axis = NODE_SIDES[ns].axis
      const grid_line = {
        axis: line_axis,
        node_id: node.id
      }
      if (line_axis === VERTICAL_AXIS_VECTOR) {
        grid_line.x = node_rect[ns]
      }
      else {
        grid_line.y = node_rect[ns]
      }

      grid_line.id = generate_node_grid_line_id({
        node,
        node_side: ns,
        axis: line_axis,
        x: grid_line.x,
        y: grid_line.y
      })

      result.push(grid_line)
    })

    // create lines for center
    const center_point = {
      x: node_rect.x + node_rect.width / 2,
      y: node_rect.y + node_rect.height / 2
    }

    result.push({
      id: generate_node_grid_line_id({
        node,
        node_side: 'center',
        axis: VERTICAL_AXIS_VECTOR,
        x: center_point.x
      }),
      node_id: node.id,
      axis: VERTICAL_AXIS_VECTOR,
      x: center_point.x
    })
    result.push({
      id: generate_node_grid_line_id({
        node,
        node_side: 'center',
        axis: HORIZONTAL_AXIS_VECTOR,
        y: center_point.y
      }),
      node_id: node.id,
      axis: HORIZONTAL_AXIS_VECTOR,
      y: center_point.y
    })
  })

  if (related_node) {
    const dragging_parent_node = canvas_store.nodes[related_node.parent_id]
    if (!dragging_parent_node) return
    // create lines for page view
    result.push({
      id: 'page-view-left-line',
      node_id: 'node-canvas',
      axis: VERTICAL_AXIS_VECTOR,
      x: canvas_store.page_rect.left
    })
    result.push({
      id: 'page-view-right-line',
      node_id: 'node-canvas',
      axis: VERTICAL_AXIS_VECTOR,
      x: canvas_store.page_rect.left + canvas_store.page_rect.width
    })
  }

  return result
}

export const create_snap_targets = (grid_lines) => {
  if (!grid_lines) return
  let result = []

  const vertical_lines = []
  const horizontal_lines = []

  grid_lines.forEach((gl) => {
    // create one side targets
    let snap_target = {
      active: false,
      grid_lines: [gl]
    }
    // grouping lines
    if (gl.axis === VERTICAL_AXIS_VECTOR) {
      vertical_lines.push(gl)
      snap_target.x = gl.x
    }
    else {
      horizontal_lines.push(gl)
      snap_target.y = gl.y
    }
    result.push(snap_target)
  })

  // create cross line targets
  vertical_lines.forEach((vl) => {
    horizontal_lines.forEach((hl) => {
      let snap_target = {
        active: false,
        grid_lines: [vl, hl],
        x: vl.x,
        y: hl.y
      }
      result.unshift(snap_target)
    })
  })
  return result
}

export const end_dragging = () => {
  const editor_store = stores.use_editor()

  editor_store.dragging.node = null
  editor_store.dragging.value = false
}

export const refresh_node_orders = () => {
  const canvas_store = stores.use_canvas()
  const handle = (ns) => {
    ns.forEach((n, i) => {
      canvas_store.nodes[n.id].order = i
      if (n.children) handle(n.children)
    })
  }
  handle(canvas_store.nested_nodes)
}

export const copy_nodes = (nodes) => {
  const canvas_store = stores.use_canvas()

  let result = []

  const copy = (node) => {
    const exclude_keys = ['created_at', 'updated_at']
    let result = cloneDeep(node)

    exclude_keys.forEach((k) => {
      delete result[k]
    })
    return result
  }

  const handle = (node) => {
    let descendants = {}

    get_node_descendants(node.id, Object.values(canvas_store.nodes)).forEach(
      (dn) => {
        descendants[dn.id] = copy(dn)
      }
    )

    const nested_descendants = nest_nodes(descendants)

    let cloned_node = copy(node)

    cloned_node.children = nested_descendants

    refresh_node_id(cloned_node)

    return cloned_node
  }

  nodes.forEach((n) => {
    result.push(handle(n))
  })

  return result
}

export const delete_node = node_id => {
  const canvas_store = stores.use_canvas()

  const node = canvas_store.nodes[node_id]
  // we don't allow to delete headers and footers
  if (![HEADER_CODE, FOOTER_CODE].includes(node.code)) {
    canvas_store.$patch((state) => {
      delete state.nodes[node_id]

      if (!node.page_id) {
        delete state.layout_nodes[node_id]
      }

      get_node_descendants(
        node_id,
        Object.values(canvas_store.nodes)
      ).forEach((cn) => {
        delete state.nodes[cn.id]

        if (!cn.page_id) {
          delete state.layout_nodes[cn.id]
        }

        // event for each child node
        create_bulk_command({
          object_type: 'Node',
          object_id: cn.id,
          code: 'Delete',
          data: null
        })
      })
    })

    // bulk command for each node
    create_bulk_command({
      object_type: 'Node',
      object_id: node_id,
      code: 'Delete',
      data: null
    })
  }
}

export const refresh_focus_boxes = () => {
  const editor_store = stores.use_editor()
  if (editor_store.selected_node) {
    if (!is_node_visible(editor_store.selected_node.id)) {
      editor_store.$patch((state) => {
        state.selected_node = null
      })
    }
  }
  if (editor_store.selected_layout_node) {
    if (!is_node_visible(editor_store.selected_layout_node.id)) {
      editor_store.$patch((state) => {
        state.selected_layout_node = null
      })
    }
  }
  if (editor_store.hovered_node) {
    if (!is_node_visible(editor_store.hovered_node.id)) {
      editor_store.$patch((state) => {
        state.hovered_node = null
      })
    }
  }
  if (editor_store.hovered_layout_node) {
    if (!is_node_visible(editor_store.hovered_layout_node.id)) {
      editor_store.$patch((state) => {
        state.hovered_layout_node = null
      })
    }
  }
}

export const locate_cursor_directions_from_node = (coordinates, node_id) => {
  // coordinates are relative with canvas
  const result = []
  const node_rect = get_node_rect_on_canvas(node_id)
  if (!node_rect) return result

  const node_center = {
    x: node_rect.left + node_rect.width / 2,
    y: node_rect.top + node_rect.height / 2
  }
  let horizontal_direction
  let vertical_direction

  if (coordinates.x > node_center.x) {
    horizontal_direction = NODE_RIGHT_SIDE
  }
  else if (coordinates.x < node_center.x) {
    horizontal_direction = NODE_LEFT_SIDE
  }
  else {
    horizontal_direction = NODE_CENTER
  }

  if (coordinates.y > node_center.y) {
    vertical_direction = NODE_BOTTOM_SIDE
  }
  else if (coordinates.y < node_center.y) {
    vertical_direction = NODE_TOP_SIDE
  }
  else {
    vertical_direction = NODE_CENTER
  }

  if (vertical_direction) result.push(vertical_direction)
  if (horizontal_direction) result.push(horizontal_direction)

  return result
}

export const calculate_distance_from_2_points = (point_a, point_b) => {
  const delta_x = Math.abs(point_a.x - point_b.x)
  const delta_y = Math.abs(point_a.y - point_b.y)
  return Math.sqrt(delta_x ** 2 + delta_y ** 2)
}

export const get_nearest_distance_to_node = (coordinates, node_id) => {
  // calculate distance to node's center
  // coordinates are relative with canvas

  const node_rect = get_node_rect_on_canvas(node_id)
  if (!node_rect) return null

  const distances = []

  Object.keys(NODE_EDGES).forEach((ne) => {
    NODE_EDGES[ne].forEach((ns) => {
      const node_coords = {x: 0, y: 0}
      if (ns === NODE_LEFT_SIDE) {
        node_coords.x = node_rect.left
        node_coords.y = node_rect.y + node_rect.height / 2
      }
      else if (ns === NODE_RIGHT_SIDE) {
        node_coords.x = node_rect.left + node_rect.width
        node_coords.y = node_rect.y + node_rect.height / 2
      }
      else if (ns === NODE_TOP_SIDE) {
        node_coords.x = node_rect.left + node_rect.width / 2
        node_coords.y = node_rect.y
      }
      else {
        node_coords.x = node_rect.left + node_rect.width / 2
        node_coords.y = node_rect.y + node_rect.height
      }
      distances.push(calculate_distance_from_2_points(coordinates, node_coords))
    })
  })

  return Math.min(...distances)
}

export const get_nearest_node = (
  {x, y},
  types = null,
  parent_ids = null,
  exclude_ids = null,
  nodes = null
) => {
  // x, y are coordinates on canvas

  const canvas_store = stores.use_canvas()

  let result

  if (!nodes) nodes = Object.values(canvas_store.nodes)
  if (exclude_ids && exclude_ids.length) {
    nodes = nodes.filter((n) => !exclude_ids.includes(n.id))
  }
  if (types && types.length) {
    nodes = nodes.filter((n) => types.includes(n.type))
  }
  if (parent_ids && parent_ids.length) {
    nodes = nodes.filter((n) => parent_ids.includes(n.parent_id))
  }

  nodes.forEach((node) => {
    const distance = get_nearest_distance_to_node({x, y}, node.id)

    if (distance !== null) {
      if (result) {
        if (result.distance > distance) {
          result.node = node
          result.distance = distance
        }
      }
      else {
        result = {
          node: node,
          distance: distance
        }
      }
    }
  })

  if (!result) return null

  return result.node
}

export const get_visible_nodes_on_viewport = (exclude_ids = null) => {
  const canvas_store = stores.use_canvas()

  if (!exclude_ids) exclude_ids = []

  const screen_rect = {
    top: 0,
    left: 0,
    bottom: window.innerHeight,
    right: window.innerWidth
  }
  return Object.values(canvas_store.nodes).filter((vn) => {
    if (!is_node_visible(vn.id)) return false
    if (exclude_ids.includes(vn.id)) return false
    const dom_node = get_node_el(vn.id)
    if (!dom_node) return false
    const rect = dom_node.getBoundingClientRect()
    return (
      rect.top >= screen_rect.top &&
      rect.top <= screen_rect.bottom &&
      rect.left >= screen_rect.left &&
      rect.left <= screen_rect.right
    )
  })
}

const refresh_node_id = (node, parent = null) => {
  node.id = gen_uuid4()
  if (parent) node.parent_id = parent.id
  const children = node.children
  if (children)
    children.forEach((child) => {
      refresh_node_id(child, node)
    })
}

const refresh_node_parent_ids = node_id => {
  const canvas_store = stores.use_canvas()
  const node = canvas_store.nodes[node_id]

  const parent_ids = []

  const handle = parent => {
    parent_ids.push(parent.id)

    if (parent.parent_id) {
      return handle(canvas_store.nodes[parent.parent_id])
    }
  }

  if (node.parent_id && node.type !== LAYOUT_TYPE) {
    const parent = canvas_store.nodes[node.parent_id]
    handle(parent)
  }
  canvas_store.$patch(state => {
    state.nodes[node_id].parent_ids = parent_ids
  })
}

export const select_element_node = (node_id) => {
  const canvas_store = stores.use_canvas()
  const editor_store = stores.use_editor()
  editor_store.$patch((state) => {
    state.selected_node = canvas_store.nodes[node_id]
  })
  const layout = get_node_layout(node_id)
  select_layout_node(layout.id)
}

export const select_layout_node = (node_id) => {
  const editor_store = stores.use_editor()
  const canvas_store = stores.use_canvas()
  editor_store.$patch((state) => {
    state.selected_layout_node = canvas_store.nodes[node_id]
  })
}

export const duplicate_node = (node_id) => {
  const canvas_store = stores.use_canvas()

  const node = canvas_store.nodes[node_id]

  if ([HEADER_CODE, FOOTER_CODE].includes(node.code)) return

  const cloned_nodes = copy_nodes([node])
  if (node.type === LAYOUT_TYPE) {
    proceed_event(create_event({
      code: EDITOR_EVENT_ADD_NODE_CODE,
      data: {
        type: ADDING_LAYOUT_NODE_TYPE,
        nodes: cloned_nodes,
        autofocus: true
      }
    }))
  } else {
    const parent_node = canvas_store.nodes[node.parent_id]
    proceed_event(
      create_event({
        code: EDITOR_EVENT_ADD_NODE_CODE,
        data: {
          type: ADDING_ELEMENT_NODE_TYPE,
          nodes: cloned_nodes,
          destination_id: parent_node.id,
          autofocus: true
        }
      })
    )
  }

  return cloned_nodes[0].id
}

export const copy_node = (node_id) => {
  const canvas_store = stores.use_canvas()
  const node = canvas_store.nodes[node_id]
  if ([HEADER_CODE, FOOTER_CODE].includes(node.code)) return
  const cloned_nodes = copy_nodes([node])
  canvas_store.$patch((state) => {
    state.carry_nodes = cloned_nodes
  })
}

export const paste_node = (node_id) => {
  const canvas_store = stores.use_canvas()
  const carry_nodes = canvas_store.carry_nodes
  if (is_empty(carry_nodes)) return

  proceed_event(
    create_event({
      code: EDITOR_EVENT_ADD_NODE_CODE,
      data: {
        type: ADDING_ELEMENT_NODE_TYPE,
        nodes: carry_nodes,
        destination_id: node_id
      }
    })
  )
}

export const update_template = async () => {
  const editor_store = stores.use_editor()
  const context_store = stores.use_context()
  const services = use_services()

  editor_store.updating_template.in_progress = true
  editor_store.updating_template.error = null

  let payload = {
    id: context_store.template.id,
    e401_redirect: context_store.template.e401_redirect,
    autosave: context_store.template.autosave
  }

  const response = await services.CmsService.update_template(payload)
  if (response.status !== 200) {
    editor_store.updating_template.error = response.data.error
    editor_store.updating_template.message = response.data.message
  }
  editor_store.updating_template.in_progress = false
}

export const change_page = (page) => {
  const canvas_store = stores.use_canvas()

  canvas_store.$patch((state) => {
    state.page = page
  })

  reset_focus_boxes()
  refresh_visible_nodes()
}

export const create_page = async ({
                                    name,
                                    endpoint,
                                    copy_background,
                                    copy_body,
                                    indexed,
                                    group,
                                    type,
                                    auth_required
                                  }) => {
  const canvas_store = stores.use_canvas()

  let result = {
    id: gen_uuid4(),
    name,
    endpoint,
    type,
    group,
    auth_required,
    indexed,
    hide_footer: false,
    hide_header: false,
    template_id: canvas_store.page.template_id
  }

  if (copy_background) {
    result.background = cloneDeep(canvas_store.page.background)
  }

  let nodes = []

  if (copy_body) {
    nodes = nodes.concat(
      copy_nodes(
        Object.values(canvas_store.nodes).filter(
          (n) => n.page_id === canvas_store.page.id && n.code === SECTION_CODE
        )
      )
    )
  }
  else {
    nodes.push(init_node(SECTION_CODE))
  }

  canvas_store.$patch((state) => {
    if (indexed) {
      state.pages.forEach((p) => (p.indexed = false))
    }
    state.pages.push(result)

    create_bulk_command({
      object_type: 'Page',
      object_id: result.id,
      code: 'Create',
      data: result
    })

    Object.values(flatten_nodes(nodes)).forEach((n, index) => {
      n.page_id = result.id
      // state.nodes[n.id] = n

      create_bulk_command({
        timestamp: new Date().getTime() + (index + 1) * 5,
        object_type: 'Node',
        object_id: n.id,
        code: 'Create',
        data: n
      })
    })
  })

  return result
}

export const delete_page = (page_id) => {
  const canvas_store = stores.use_canvas()

  // create event
  create_bulk_command({
    object_type: 'Page',
    object_id: page_id,
    code: 'Delete',
    data: null
  })

  canvas_store.$patch((state) => {
    if (state.page.id === page_id) {
      // change to another page if the page is selected on the screen
      change_page(state.pages.find((p) => p.indexed))
    }
    const index = state.pages.findIndex((p) => p.id === page_id)
    if (index >= 0) state.pages.splice(index, 1)

    // delete page's nodes
    Object.values(state.nodes)
      .filter((n) => n.page_id === page_id)
      .forEach((n) => {
        delete state.nodes[n.id]

        // create bulk command
        create_bulk_command({
          object_type: 'Node',
          object_id: n.id,
          code: 'Delete',
          data: null
        })
      })
  })
}

export const publish_template = async () => {
  const editor_store = stores.use_editor()
  const context_store = stores.use_context()
  const services = use_services()

  editor_store.publishing.in_progress = true
  editor_store.publishing.error = null

  const response = await services.CmsService.publish({
    template_id: context_store.template.id
    // published_version: context_store.template.published_version,
  })
  if (response.status === 200) {
    context_store.$patch((state) => {
      state.template.published_version = response.data.version
    })
  }
  else {
    const $q = useQuasar()
    $q.notify({
      type: 'negative',
      message: response.data.message,
      caption: response.data.error
    })
  }

  editor_store.publishing.in_progress = false

  return response.data
}

export const init_node = (code) => {
  const canvas_store = stores.use_canvas()
  const context_store = stores.use_context()

  const node_kind = NODE_KINDS[code]
  if (!node_kind) throw new Error(`Unsupported node kind: ${code}`)
  let result = node_kind.generate()

  result.scope_type = 'Template'
  result.scope_id = context_store.template.id
  result.group = canvas_store.page.group

  return result
}

export const init_nodes_from_block = (block) => {
  const nodes = []
  const handle = (data, parent = null) => {
    const child_nodes = data.children || []
    delete data.children
    const node_code = data.code
    let node = init_node(node_code)
    Object.assign(node, data)

    if (parent) {
      node.parent_id = parent.id
      const children = parent.children || []
      children.push(node)
      parent.children = children
    }

    if (child_nodes.length) {
      child_nodes.forEach((cn) => handle(cn, node))
    }
    return node
  }

  cloneDeep(block.nodes).forEach((n) => {
    nodes.push(handle(n))
  })
  return nodes
}

export const change_node_position = ({node_id, direction}) => {
  const canvas_store = stores.use_canvas()

  const node = canvas_store.nodes[node_id]

  let nodes = Object.values(canvas_store.flattened_nodes).filter(
    n => ![HEADER_CODE, FOOTER_CODE].includes(n.type)
  )

  if (node.type === LAYOUT_TYPE) {
    nodes = nodes.filter((n) => n.type === LAYOUT_TYPE)
  }
  else {
    const parent = canvas_store.flattened_nodes[node.parent_id]
    nodes = get_node_children(parent.id, nodes)
  }

  const index_offset = direction === 'right' ? 1 : -1
  const index = nodes.findIndex((n) => n.id === node.id)
  const new_index = index + index_offset

  if (new_index < 0 || new_index >= nodes.length) return

  const node_holder = nodes[new_index]

  nodes[new_index] = node
  nodes[index] = node_holder

  nodes.forEach((n, i) => {
    if (n.order !== i) {
      n.order = i
      canvas_store.nodes[n.id] = n
    }
  })
}

export const create_event = ({
                               code,
                               data,
                               timestamp
                             }) => {
  const editor_store = stores.use_editor()

  const event = {
    timestamp: timestamp ?? new Date().getTime(),
    status: EDITOR_EVENT_CREATED_STATUS,
    code,
    data
  }
  editor_store.$patch((state) => {
    state.events.push(event)
  })

  return event
}

export const create_bulk_command = ({
                                      object_type,
                                      object_id,
                                      code,
                                      data,
                                      timestamp
                                    }) => {
  const editor_store = stores.use_editor()

  const cmd = {
    timestamp: timestamp ?? new Date().getTime(),
    status: EDITOR_EVENT_CREATED_STATUS,
    meta: {},
    object_type,
    object_id,
    code,
    data
  }
  editor_store.$patch((state) => {
    state.bulk_commands.push(cmd)
  })
  return cmd
}

export const proceed_event = event => {
  switch (event.code) {
    case EDITOR_EVENT_MOVE_NODE_CODE:
      event.data.node_ids.forEach(node_id => {
        move_node({
          node_id,
          destination_id: event.data.destination_id,
          autofocus: event.data.autofocus
        })
      })
      break
    case EDITOR_EVENT_ADD_NODE_CODE:
      event.data.nodes.forEach(node => {
        add_node({
          type: event.data.type,
          node,
          destination_id: event.data.destination_id,
          autofocus: event.data.autofocus
        })
      })
      break
    case EDITOR_EVENT_DELETE_NODE_CODE:
      event.data.node_ids.forEach(node_id => {
        delete_node(node_id)
      })
      break
    case EDITOR_EVENT_CHANGE_NODE_POSITION_CODE:
      change_node_position({node_id: event.data.node_id, direction: event.data.direction})
      break
    case EDITOR_EVENT_UPDATE_NODE_CODE:
      break
    default:
  }

  event.status = EDITOR_EVENT_PROCESSED_STATUS
}

export const proceed_bulk_commands = async commands => {
  const services = use_services()

  commands.forEach((cmd) => {
    cmd.status = EDITOR_EVENT_PROCESSING_STATUS
    if (cmd?.data?.meta?.values?.password) {
      cmd.data.meta.values.password = ''
    }
    if (cmd?.data?.meta?.values?.email) {
      cmd.data.meta.values.email = ''
    }
    if (cmd?.data?.meta?.values?.token) {
      cmd.data.meta.values.token = ''
    }
    if (cmd?.data?.meta?.values?.new_password) {
      cmd.data.meta.values.new_password = ''
    }
  })

  const response = await services.CmsService.bulk_write({
    pipline: commands
  })

  if (response.status === 200) {
    commands.forEach((cmd) => {
      cmd.status = response.data[cmd.timestamp].status
    })
  }
  else {
    commands.forEach((cmd) => {
      cmd.status = EDITOR_EVENT_FAILED_STATUS
      cmd.meta = response.data
    })
  }
}

export const start_autosave = () => {
  const editor_store = stores.use_editor()
  editor_store.$patch((state) => {
    if (state.autosave_interval !== null) {
      clearInterval(state.autosave_interval)
    }
    state.autosave_interval = setInterval(async () => {
      const processing_commands = editor_store.bulk_commands.filter(
        (cmd) => cmd.status === EDITOR_BULK_COMMAND_PROCESSING_STATUS
      )
      if (processing_commands.length) {
        // we only call one by one to avoid race condition issue
        return
      }
      const created_commands = editor_store.bulk_commands.filter(
        (cmd) => cmd.status === EDITOR_BULK_COMMAND_CREATED_STATUS
      )

      if (!created_commands.length) return

      await proceed_bulk_commands(created_commands)

      remove_processed_bulk_commands()

    }, 500)
  })
}

export const remove_processed_bulk_commands = () => {
  const editor_store = stores.use_editor()

  editor_store.$patch(state => {
    state.bulk_commands = state.bulk_commands.filter(cmd => cmd.status !== EDITOR_BULK_COMMAND_PROCESSED_STATUS)
  })

}

export const stop_autosave = () => {
  const editor_store = stores.use_editor()
  if (editor_store.autosave_interval !== null) {
    editor_store.$patch((state) => {
      clearInterval(state.autosave_interval)
      state.autosave_interval = null
    })
  }
}
