<template>
  <q-select
    v-bind="v_bind"
    :options="options"
    @update:model-value="handle_changes"
  >
    <template v-slot:append v-if="append_icon">
      <q-icon :name="append_icon" />
    </template>
    <template v-slot:prepend v-if="prepend_icon">
      <q-icon :name="prepend_icon" />
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section>
          <!--eslint-disable vue/no-v-text-v-html-on-component-->
          <q-item-label
            class="text-capitalize"
            style="color: black"
            v-html="t(scope.opt.label)"
          />
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:selected v-if="selected_option">
      <q-item-label :lines="1">
        <span :dom-key="selected_option.label">
          {{ t(selected_option.label) }}
        </span>
      </q-item-label>
    </template>
  </q-select>
</template>
<script setup>
import { defineProps, computed, onMounted, defineEmits, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  modelValue: { type: String },
  label: { type: String },
  cabin_classes: { type: Array, default: () => [] },
  append_icon: { type: String },
  prepend_icon: { type: String },
  hide_label_mode: { type: Boolean }
})
const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()

const _options = ref([
  {
    id: 'Economy',
    label: 'components.shopping.forms.common.cabin-select.options.economy'
  },
  {
    id: 'Premium Economy',
    label: 'components.shopping.forms.common.cabin-select.options.premium'
  },
  {
    id: 'Business',
    label: 'components.shopping.forms.common.cabin-select.options.business'
  },
  {
    id: 'First',
    label: 'components.shopping.forms.common.cabin-select.options.first'
  }
])

const options = computed({
  get: () => _options.value,
  set: (new_options) => (_options.value = new_options)
})

const v_bind = computed(() => {
  const result = {
    'model-value': props.modelValue,
    color: 'black',
    'option-label': 'label',
    'option-value': 'id'
  }

  if (props.hide_label_mode) {
    result.class = 'expedia-cabin-class-select'
  }

  const hide_label_condition = props.hide_label_mode
    ? !!props.modelValue
    : false
  if (props.label && !hide_label_condition) {
    result.label = props.label
  }

  return result
})

const selected_option = computed(() => {
  return options.value.find((o) => o.id === props.modelValue)
})

onMounted(() => {
  if (props.cabin_classes?.length > 0) {
    let result = []

    options.value.forEach((opt) => {
      if (props.cabin_classes.find((i) => i === opt.id)) {
        result.push(opt)
      }
    })
    options.value = result

    const data = result?.map((r) => r?.id)
    if (!data?.includes(props.modelValue)) {
      handle_changes(data?.[0])
    }
  }
})

const handle_changes = (val) => {
  emit('update:modelValue', val?.id ?? val)
}
</script>

<style lang="scss">
.expedia-cabin-class-select {
  min-width: 140px;

  .q-field__control-container {
    padding-top: 0 !important;
  }

  .q-field__label {
    top: 6px !important;
    transform: none !important;
  }

  .q-field__control,
  .q-field__native {
    min-height: 32px !important;
  }
  .q-field__marginal {
    height: 32px !important;
  }
}
</style>
