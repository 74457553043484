<template>
  <form-builder
    v-if="payload"
    :key="update_key"
    :fields="
      design === EXPEDIA_PACKAGE_FORM_DESIGN ? meta_expedia.fields : meta.fields
    "
    :node_id="node.id"
    :style="{ '--q-primary': color }"
    product_name="hotel"
    @submit="submit"
  />
</template>
<script setup>
import { computed, defineEmits, onMounted, ref } from 'vue'
import key_by from 'lodash.keyby'
import dayjs from 'dayjs'

import { stores } from 'src/stores'
import { EXPEDIA_PACKAGE_FORM_DESIGN } from 'src/constants'

import FormBuilder from 'src/components/common/form-builder'
import { get_meta_default } from './meta_default'
import { get_meta_expedia } from './meta_expedia'

const emit = defineEmits(['submit'])
const update_key = ref(0)

const props = defineProps({
  product: { type: Object, required: true },
  hotel: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  design: { type: String },
  node: { type: Object },
  setting: { type: Object },
  place_types: { type: Array, default: () => [] }
})
const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(() => package_store.payload[props.node?.id]?.['hotel'])

onMounted(() => {
  if (props.default_destination && payload.value) {
    payload.value.place = props.default_destination
  }
  const default_values = props.setting?.default_values ?? {}

  if (default_values?.place || default_values?.place === null) {
    payload.value.place = default_values.place
  }
  if (default_values?.dates) {
    payload.value.dates = default_values.dates
  }
  if (default_values?.travelers) {
    payload.value.travelers = default_values.travelers
  }
  if (default_values?.nationality) {
    payload.value.nationality = default_values.nationality
  }
  update_key.value += 1
})

const traveler_limits = key_by(props.hotel.traveler_limits, 'traveler_type')

const rules = {
  dates: [(val) => !!val || 'Required'],
  place: [(val) => !!val || 'Required'],
  travelers: []
}

const meta = get_meta_default({
  props,
  rules,
  payload,
  place_types: props.place_types,
  traveler_limits,
  group: context_store.group
})

const meta_expedia = get_meta_expedia({
  props,
  rules,
  place_types: props.place_types,
  traveler_limits,
  group: context_store.group
})

const submit = () => {
  const { travelers, place, dates, nationality } = payload.value || {}

  const expectation = {
    nationality: nationality?.code ?? (nationality || undefined),
    ht_des_code: place.type === 'airport' ? place.code : place.id,
    ht_checkin_date: dates[0],
    ht_checkout_date: dates[1],
    ht_des_type:
      place.type === 'hotel'
        ? 'property_id'
        : place.type === 'airport'
        ? 'airport_code'
        : 'place_id',
    is_separate: false
  }

  if (!nationality?.code) {
    delete expectation.nationality
  }

  const data = {
    process: 'hotel',
    place_type: place.type,
    place_id: place.id,
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    package_id: props.hotel.id,
    travelers: JSON.stringify(travelers),
    nationality: nationality?.code ?? (nationality || undefined),
    expectation: JSON.stringify(expectation),
    flight_campaign: props.flight_campaign,
    partner_id: props.partner_id
  }

  if (!data.nationality) {
    delete data.nationality
  }
  
  
  if (place.type === 'hotel') {
    package_store.add_viewed({
      data,
      key: `hotel-${expectation.ht_des_code}`,
      payload: {
        name: place.name,
        dates: `${dayjs(dates[0]).format('ddd, MMM DD')} - ${dayjs(dates[1]).format('ddd, MMM DD')}`,
        travelers: travelers.length,
        rooms: Object.keys(key_by(travelers, 'room'))?.length ?? 1,
        avatar: place.avatar?.urls?.md ?? place.avatar?.urls?.sm ?? place.avatar?.urls?.lg,
        star: place.star,
        address: place.street_address,
        nationality: nationality?.code ?? nationality,
        min_date: dates[0]
      }
    })
  } else {
    package_store.add_selected({
      data,
      key: `hotel-${expectation.ht_des_code}`,
      created_at: new Date().getTime(),
      payload: {
        min_date: dates[0],
        from: place.name,
        dates: `${dayjs(dates[0]).format('ddd, MMM DD')} - ${dayjs(dates[1]).format('ddd, MMM DD')}`,
        travelers: travelers.length,
        rooms: Object.keys(key_by(travelers, 'room'))?.length ?? 1
      }
    })
  }
  emit('submit', data)
}
</script>
