<template>
  <q-btn v-bind="btn_bind" class="egs-buttons" @click="dialog_showed = true" :loading="loading" no-caps>
    <q-dialog @before-hide="email = ''" v-model="dialog_showed">
      <q-card style="max-width: 99vw; width: 500px">
        <q-item>
          <q-item-section>
            <q-item-label class="text-h6">Forget Password</q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn v-close-popup icon="close" flat round size="sm" />
          </q-item-section>
        </q-item>
        <q-separator />
        <q-card-section>
          <q-input autofocus :rules="emailRules" v-model="email" label="Email" @focus="handle_focus_input" />
          <q-item class="bg-red-10 text-white" v-if="!!forgot_password_error">
            <q-item-section avatar>
              <q-icon name="info" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-white text-bold">Error</q-item-label>
              <q-item-label class="text-white" caption lines="1">{{forgot_password_error}}</q-item-label>
            </q-item-section>
          </q-item>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn @click="dialog_showed = false" v-close-popup flat color="primary" label="Cancel" />
          <q-btn @click="handle_submit_form" :loading="is_loading_forgot_password" color="primary" label="Submit" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-btn>
</template>

<script setup>
import {computed, defineProps, ref} from 'vue'
import {
  NODE_BASE_PROPS,
  DEFAULT_BUTTON_DESIGN,
  TEXT_BUTTON_DESIGN,
  PAGE_REDIRECT_EVENT_HANDLER,
  PUBLISH_MODE,
  EMBED_MODE,
  LINK_REDIRECT_EVENT_HANDLER,
  B2B_DASHBOARD_REDIRECT_HANDLER
} from 'src/constants'
import {
  generate_common_node_styles,
} from 'src/composables/canvas'
import {stores} from 'src/stores'
import { use_services } from "src/composables/services";
import {Notify} from 'quasar'
import { useRouter } from 'vue-router'

const props = defineProps(NODE_BASE_PROPS)

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const services = use_services();
const service = services.IamService;
const router = useRouter()

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const loading = ref(false)

const dialog_showed = ref(false);
const email = ref("");
const forgot_password_error = ref("");
const is_loading_forgot_password = ref(false);
const emailRules = ref([
  (val) => !!val || 'Field is required',
  (val) => emailRegex.test(val) || 'Invalid Email!',]
)

const handle_focus_input = () => forgot_password_error.value = ''

const trigger_redirect = () => {
  const settings = props.node.meta.after_submit
  if (settings.handler === PAGE_REDIRECT_EVENT_HANDLER.id) {
    if (context_store.mode === PUBLISH_MODE) {
      router.push({ path: settings.page_endpoint })
    } else if (context_store.mode === EMBED_MODE) {
      window.location.href = settings.page_endpoint
    } else {
      canvas_store.$patch((state) => {
        state.page = state.pages.find(
          (p) => p.endpoint === settings.page_endpoint
        )
      })
    }
  } else if (settings.handler === LINK_REDIRECT_EVENT_HANDLER.id) {
    window.location.href = settings.link
  } else if (settings.handler === B2B_DASHBOARD_REDIRECT_HANDLER.id) {
    window.location.href = `https://${context_store.app.booking_app_base_domain}/b2b-auth/callback?access_token=${auth_store.user.access_token}`
  }
}

const handle_submit_form = async () => {
  if (!email.value) return;
  is_loading_forgot_password.value = true;
  forgot_password_error.value = null;
  
  const res = await service.forget_password_with_token({ email: email.value, scope_type: 'Agent',app_id: context_store.app.id });
  if (res?.data?.error) {
    forgot_password_error.value = res?.data?.message ??'Something went wrong';
  } else {
    dialog_showed.value = false;
    Notify.create({
      type: "positive",
      message: "We have sent a reset token to your email!",
      actions: [
        {
          icon: "close",
          color: "white",
          round: true,
        },
      ],
    });
  }
  is_loading_forgot_password.value = false;
  trigger_redirect()
};

const styles = computed(() => {

  const meta = props.node.meta || {}

  let result = generate_common_node_styles(props.node)

  if (meta.color && meta.design !== TEXT_BUTTON_DESIGN) result['background-color'] = meta.color
  if (meta.text_color) result['color'] = meta.text_color

  result['width'] = '100%'

  return result
})

const btn_bind = computed(() => {

  const meta = props.node.meta || {}

  let result = {
    'style': styles.value,
    'label': meta.label[context_store.language.code],
  }


  if (meta.design && meta.design !== DEFAULT_BUTTON_DESIGN) {
    result[meta.design] = true
  }

  if (meta.size) {
    result['size'] = meta.size
  }

  return result
})

</script>
