<template>
  <div :style="styles" :class="classes">
    <div v-html="output_html" v-css="node.meta.css" ></div>
  </div>
</template>
<script setup>
import {computed, defineProps} from 'vue'
import {NODE_BASE_PROPS} from 'src/constants'
import {generate_common_node_styles} from 'src/composables/canvas'
import Handlebars from "handlebars";
import {stores} from "src/stores";

const props = defineProps(NODE_BASE_PROPS)
const context_store = stores.use_context()

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})

const output_html = computed(()=> {
  const compiled_template = Handlebars.compile(props.node.meta.html);
  return compiled_template(props.node.meta.variables[context_store.language.code] ?? {})
}) ;

const classes = computed(() => {
  return []
})


</script>
