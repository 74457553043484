import { createI18n } from 'vue-i18n'
import { DEFAULT_LANGUAGE } from 'src/constants'

export const i18n = createI18n({
  locale: DEFAULT_LANGUAGE.code,
  globalInjection: true,
  messages: {
    'en-US': {
      'common.dates': 'dates',
      'common.any': 'any',
      'common.1-star': '1 stars',
      'common.2-stars': '2 stars',
      'common.3-stars': '3 stars',
      'common.4-stars': '4 stars',
      'common.5-stars': '5 stars',
      'common.or': 'or',
      'common.search': 'Search',
      'common.going-to': 'Going to',
      'common.round-trip': 'Round trip',
      'common.one-way': 'One way',
      'common.origin': 'origin',
      'common.destination': 'destination',
      'common.departure-return': 'Dates',
      'common.travelers': 'Traveller',
      'common.adults': 'adults',
      'common.children': 'children',
      'common.infants': 'infants',
      'common.cabin-class': 'cabin class',
      'common.search-now': 'Search Now',
      'common.airport': 'airport',
      'common.pickup-date': 'pickup date',
      'common.pickup-time': 'pickup time',
      'common.return-date': 'return date',
      'common.return-time': 'return time',
      'common.where-are-you-going': 'Destination',
      'common.checkin-checkout': 'Dates',
      'common.part-of-my-stay': 'Only Need Accommodation For Part Of My Trip',
      'common.pickup-location': 'pickup location',
      'common.driver-age': 'driver age',
      'common.dropoff-date': 'dropoff date',
      'common.dropoff-time': 'dropoff time',
      'common.multi-city': 'Multi City',
      'components.shopping.forms.common.cabin-select.options.first': 'First',
      'components.shopping.forms.common.cabin-select.options.economy':
        'Economy',
      'components.shopping.forms.common.cabin-select.options.premium':
        'Premium Economy',
      'components.shopping.forms.common.cabin-select.options.business':
        'Business',
      'components.date-picker.days':
        'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday',
      'components.date-picker.day-short': 'Sun_Mon_Tue_Wed_Thu_Fri_Sat',
      'components.date-picker.months':
        'January_February_March_April_May_June_July_August_September_October_November_December',
      'components.date-picker.months-short':
        'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec',
      'common.add-room': 'Add Room',
      'common.add-max-room': 'Maximum room is 3 per booking.',
      'common.add-age-room':
        ' Age of children infants should be considered from the date of departure.',
      'common.select-nationality': 'Nationality'
    }
  },
  legacy: false
})
