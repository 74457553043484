<template>
  <div class="egs-agency-logo" v-bind="bind">
    <q-img v-if="cms_settings" :src="cms_settings.logo.url" fit="cover" width="100%" />
  </div>
</template>
<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'
import { NODE_BASE_PROPS } from 'src/constants'
import { use_services } from 'src/composables/services'
import { stores } from 'src/stores'

const props = defineProps(NODE_BASE_PROPS)
const services = use_services()
const cms_settings = ref(null)
const context_store = stores.use_context()

const bind = computed(() => {
  let result = {}
  result['style'] = { ...props.styles }
  return result
})

onMounted(async () => {
  const b2b_context = await services.AppService.get_b2b_context()
  const b2b_agent = await services.AppService.get_b2b_agent({ id: b2b_context?.data?.accessor?.scope_id })
  if (b2b_agent?.data?.settings?.cms_settings) {
    cms_settings.value = b2b_agent?.data?.settings?.cms_settings
  } else {
    cms_settings.value = context_store.app.cms_settings
  }
})

</script>