<template>
  <q-tabs v-if="type === 'tabs'" v-model="active_tab" dense>
    <q-tab
      v-for="option in options"
      :key="option.id"
      :label="$t(option.label)"
      :name="option.id"
      :style="gen_tab_styles(option)"
      class="text-capitalize"
      no-caps
      style="min-height: 32px"
      @click="select(option)"
    />
  </q-tabs>
  <q-btn-group v-else rounded>
    <q-btn
      v-for="option in options"
      :key="option.id"
      :style="gen_option_styles(option)"
      :text-color="is_selected(option) ? 'white' : 'black'"
      class="text-capitalize"
      no-caps
      size="sm"
      @click="select(option)"
    >
      <span style="text-transform: capitalize;" :dom-key="option.label">
        {{ $t(option.label) }}
      </span>
    </q-btn>
  </q-btn-group>
</template>
<script setup>
import { ref } from 'vue'
import compact from 'lodash.compact'

const props = defineProps({
  color: { type: String, default: '#1976d2' },
  type: { type: String, default: 'buttons' },
  show_multi_city: { type: Boolean, default: false },
  payload: { type: Object },
  round_trip: { type: String },
  multi_city: { type: String }
})

const options = compact([
  {
    label: 'common.round-trip',
    id: 'round-trip'
  },
  {
    label: 'common.one-way',
    id: 'one-way'
  },
  props.show_multi_city && {
    label: 'common.multi-city',
    id: 'multi-city'
  }
])

const active_tab = ref(
  props.payload?.value?.[props.round_trip]
    ? 'round-trip'
    : props.payload?.value?.[props.multi_city]
      ? 'multi-city'
      : 'one-way'
)

const is_selected = (option) => {
  return option.id === active_tab.value
}

const select = (option) => {
  active_tab.value = option.id
  props.payload.value[props.round_trip] = option.id === 'round-trip'
  props.payload.value[props.multi_city] = option.id === 'multi-city'
}

const gen_option_styles = (option) => {
  let result = {}

  let background_color = 'white'

  if (is_selected(option)) {
    background_color = props.color
  }
  result['background-color'] = background_color

  return result
}

const gen_tab_styles = (option) => {
  let result = {}

  let color = 'black'

  if (is_selected(option)) {
    color = props.color
  }
  result['color'] = color

  return result
}
</script>