<template>
  <div v-bind="bind">
    <package-grid-component :node="node" :meta="node.meta" />
  </div>
</template>
<script setup>
import PackageGridComponent from 'src/components/common/package-grid'
import { computed, defineProps } from 'vue'
import { NODE_BASE_PROPS } from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'

const props = defineProps(NODE_BASE_PROPS)

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})

const bind = computed(() => {
  const meta = props.node.meta
  let result = {
    style: styles.value
  }
  if (meta.bordered) {
    result['bordered'] = true
  }
  if (meta.flat) {
    result['flat'] = true
  }
  return result
})
</script>
