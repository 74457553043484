<template>
  <q-item
    v-if="group_by_countries || destination_with_country"
    class="egs-group_by_countries"
  >
    <q-list class="egs-group_by_countries-list" dense>
      <q-item-label
        :class="[
          'q-py-xs egs-destination-item',
          is_first_item && 'q-pt-sm',
          handle_active(option) && 'bg-grey-2'
        ]"
        dense
        header
        @click="handle_select(option)"
      >
        <span v-highlight="search_text" style="font-size: 14px">
          {{ option.name }}
        </span>
        <span style="font-size: 14px">
          {{ option.count ?? option.trip_count }} Packages
        </span>
      </q-item-label>
      <div
        class="egs-group_by_countries-wrap q-ml-md q-pr-md"
        v-if="!hide_cities"
      >
        <q-item
          v-for="city in option.cities"
          :key="city.id"
          class="q-py-sm"
          clickable
          dense
          @click="handle_select(city)"
        >
          <q-item-section>
            <q-item-label v-highlight="search_text" style="font-size: 14px">
              {{ city.name }}
            </q-item-label>
          </q-item-section>
          <q-item-section side style="font-size: 14px">
            {{ city.count ?? city.trip_count }} Packages
          </q-item-section>
        </q-item>
      </div>
    </q-list>
  </q-item>
  <q-item
    v-else
    :active="modelValue && modelValue.id === option.id"
    clickable
    @click="handle_select(option)"
  >
    <q-item-section side>
      <q-icon v-if="option.type === 'airport'" name="flight" />
      <q-icon v-else-if="isNumber(option.star)" name="hotel" />
      <q-icon v-else name="place" />
    </q-item-section>
    <q-item-section>
      <q-item-label v-highlight="search_text" lines="1">
        {{ option.name }}
      </q-item-label>
      <q-item-label v-highlight="search_text" caption lines="1">
        {{ option.street_address || option.long_name }}
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-item-label v-if="isNumber(option.star)" lines="1">
        <q-rating
          v-model="option.star"
          color="deep-orange-5"
          icon="star_border"
          icon-half="star_half"
          icon-selected="star"
          max="5"
          readonly
          size="1em"
        />
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<script setup>
import isNumber from 'lodash.isnumber'

const props = defineProps({
  modelValue: { type: [Object, Array, String] },
  option: { type: Object },
  search_text: { type: String, default: '' },
  is_first_item: { type: Boolean, default: false },
  group_by_countries: { type: Boolean, default: false },
  destination_with_country: { type: Boolean, default: false },
  hide_cities: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  selected_options: { type: Array, default: [] }
})

const emit = defineEmits(['select'])

const handle_active = (option) => {
  if (props.multiple) {
    return !!props.selected_options.find((value) => value.id === option.id)
  }
}

const handle_select = (option) => emit('select', option)
</script>
