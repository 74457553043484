<template>
  <div :style="styles"  :class="classes">
    <div
      v-if="Object.keys(recent_selected)?.length > 0"
      class="b5-fw-bold b5-fs-4"
      :style="{ color: meta.text_color }"
    >
      Your recent searches
    </div>
    <div
      v-if="Object.keys(recent_selected)?.length > 0"
      class="eg-cms-recent-package-list"
    >
      <template v-for="(value, key) in recent_selected" :key="key">
        <div
          class="eg-cms-recent-package-item"
          v-if="!key?.includes('undefined')"
          @click="handle_click_package(value.data)"
        >
          <div class="eg-cms-recent-package-item-icon">
            <q-icon
              size="24px"
              v-if="value.data?.process === 'bundle'"
              name="luggage"
            />
            <q-icon
              size="24px"
              v-if="value.data?.process === 'car_rental'"
              name="car_rental"
            />
            <q-icon
              size="24px"
              v-if="value.data?.process === 'hotel'"
              name="hotel"
            />
            <q-icon
              size="24px"
              v-if="value.data?.process === 'flight'"
              name="flight"
            />
            <q-icon
              size="24px"
              v-if="value.data?.process === 'stopover'"
              name="hail"
            />
            <q-icon
              size="24px"
              v-if="value.data?.process === 'tour'"
              name="tour"
            />
            <q-icon
              size="24px"
              v-if="value.data?.process === 'tour_package'"
              name="festival"
            />
            <q-icon
              size="24px"
              v-if="
                value.data?.process === 'train' &&
                value.data?.type === 'railway_station'
              "
              name="train"
            />
            <q-icon
              size="24px"
              v-if="
                value.data?.process === 'train' &&
                value.data?.type !== 'railway_station'
              "
              name="emoji_transportation"
            />
            <q-icon
              size="24px"
              v-if="value.data?.process === 'transfer'"
              name="transfer_within_a_station"
            />
            <q-icon
              size="24px"
              v-if="value.data?.process === 'multileg_holiday'"
              name="event_note"
            />
          </div>
          <div class="eg-cms-recent-package-item-content">
            <div class="b5-fw-bold eg-cms-recent-package-item-content-title">
              <span v-if="value.data?.process === 'bundle'">
                Bundle from {{ value.payload?.from }} to {{ value.payload?.to }}
              </span>
              <span v-if="value.data?.process === 'car_rental'">
                Car Rental in {{ value.payload?.from }}
              </span>
              <span v-if="value.data?.process === 'hotel'">
                Hotel in {{ value.payload?.from }}
              </span>
              <span v-if="value.data?.process === 'flight'">
                Flight from {{ value.payload?.from }} to {{ value.payload?.to }}
              </span>
              <span v-if="value.data?.process === 'stopover'">
                Stopover in {{ value.payload?.from }}
              </span>
              <span v-if="value.data?.process === 'tour'">
                Tour in {{ value.payload?.from }}
              </span>
              <span v-if="value.data?.process === 'tour_package'">
                Tour Package in {{ value.payload?.from }}
              </span>
              <span
                v-if="
                  value.data?.process === 'train' &&
                  value.data?.type === 'railway_station'
                "
              >
                Train from {{ value.payload?.from }} to {{ value.payload?.to }}
              </span>
              <span
                v-if="
                  value.data?.process === 'train' &&
                  value.data?.type !== 'railway_station'
                "
              >
                Train Hotel in {{ value.payload?.from }} to
                {{ value.payload?.to }}
              </span>
              <span v-if="value.data?.process === 'transfer'">
                Transfer from {{ value.payload?.from }} to
                {{ value.payload?.to }}
              </span>
              <span v-if="value.data?.process === 'multileg_holiday'">
                Trip to {{ value.payload?.from }}
              </span>
            </div>
            <div>{{ value.payload?.dates }}</div>
            <div>
              <span v-if="value.data?.process === 'flight'">
                <span v-if="value.payload?.type === 'round_trip'">
                  Round Trip -
                </span>
                <span v-if="value.payload?.type === 'one_way'">One Way -</span>
                {{ value.payload?.travelers }} travellers
              </span>
              <span v-if="value.data?.process === 'bundle'">
                {{ value.payload?.travelers }} travellers
              </span>
              <span v-if="value.data?.process === 'car_rental'">
                Driver {{ value.payload?.age }} years old
              </span>
              <span v-if="value.data?.process === 'hotel'">
                {{ value.payload?.travelers }} travellers -
                {{ value.payload?.rooms }} rooms
              </span>
              <span v-if="value.data?.process === 'stopover'">
                {{ value.payload?.travelers }} travellers
              </span>
              <span v-if="value.data?.process === 'tour'">
                {{ value.payload?.travelers }} travellers
              </span>
              <span v-if="value.data?.process === 'tour_package'">
                {{ value.payload?.travelers }} travellers
              </span>
              <span
                v-if="
                  value.data?.process === 'train' &&
                  value.data?.type === 'railway_station'
                "
              >
                <span v-if="value.payload?.type === 'round_trip'">
                  Round Trip -
                </span>
                <span v-if="value.payload?.type === 'one_way'">One Way -</span>
                {{ value.payload?.travelers }} travellers
              </span>
              <span
                v-if="
                  value.data?.process === 'train' &&
                  value.data?.type !== 'railway_station'
                "
              >
                {{ value.payload?.travelers }} travellers -
                {{ value.payload?.rooms }} rooms
              </span>
              <span v-if="value.data?.process === 'transfer'">
                {{ value.payload?.travelers }} travellers
              </span>
              <span v-if="value.data?.process === 'multileg_holiday'">
                {{ value.payload?.travelers }} travellers
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div
      v-if="Object.keys(viewed)?.length > 0"
      class="b5-fw-bold b5-fs-4 b5-pt-2"
       :style="{ color: meta.text_color }"
    >
      Your recently viewed properties
    </div>
    <div
      v-if="Object.keys(viewed)?.length > 0"
      class="eg-cms-recent-package-list"
    >
      <template v-for="(value, key) in viewed" :key="key">
        <div
          @click="handle_click_package(value.data)"
          v-if="!!value.payload?.avatar"
          class="eg-cms-viewed-package-item"
        >
          <div
            :style="`background-image: url(${value.payload?.avatar})`"
            class="eg-cms-viewed-package-item-avatar"
          ></div>
          <div class="eg-cms-viewed-package-item-content b5-px-2 b5-py-1">
            <div class="eg-cms-viewed-package-item-content-title">
              {{ value.payload?.name }}
            </div>
            <div class="b5-pb-2" v-if="value.payload?.star">
              <q-rating
                v-model="value.payload.star"
                :max="5"
                size="1em"
                color="amber"
                readonly
                icon="star"
              />
            </div>
            <div>
              {{ value.payload?.address }}
            </div>
            <div>
              {{ value.payload?.dates }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import { NODE_BASE_PROPS } from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import { stores } from 'src/stores'
const props = defineProps(NODE_BASE_PROPS)

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})

const package_store = stores.use_package()
const context_store = stores.use_context()
const auth_store = stores.use_auth()
const mode = context_store.mode

const meta = computed(() => props.node.meta)


const recent_selected = computed(() => {
  if (!meta.value.show_package_recent) return {}
  if (mode === 'edit' || mode === 'preview') {
    return {
      'flight-HAN-SGN': {
        data: {
          process: 'flight',
          currency_code: 'USD',
          language_code: 'en-US',
          package_id: '3dca0e8c-b1ec-4fef-913e-09b577956c6d',
          travelers: '[{"type":"adult","age":18,"room":1}]',
          expectation:
            '{"is_multi_city":false,"start_place_code":"HAN","start_place_type":"airport_code","des_code":"SGN","des_type":"airport_code","fl_cabin_class":"Economy","fl_departure_date":"2025-04-24","fl_return_date":"2025-04-25","fl_round_trip":true}',
          access_token: ''
        },
        key: 'flight-HAN-SGN',
        created_at: 1743434370631,
        payload: {
          from: 'Hanoi (HAN-Noi Bai Intl.)',
          to: 'Ho Chi Minh City (SGN-Tan Son Nhat Intl.)',
          dates: 'Thu, Apr 24 - Fri, Apr 25',
          travelers: 1,
          type: 'round_trip',
          min_date: '2025-04-24'
        }
      }
    }
  }
  const items = { ...package_store.recent.selected }
  Object.entries(items).forEach(([key, value]) => {
    const minDate = value?.payload?.min_date
      ? new Date(value.payload.min_date)
      : null
    const today = new Date()
    if (minDate && minDate < today) {
      remove_selected(key)
    }
  })

  return Object.fromEntries(
    Object.entries(items)
      .filter((a) => {
        const minDate = a[1]?.payload?.min_date
          ? new Date(a[1].payload.min_date)
          : null
        const today = new Date()
        return !minDate || minDate >= today
      })
      .sort((a, b) => {
        const dateA = a[1]?.created_at ? new Date(a[1].created_at) : new Date(0)
        const dateB = b[1]?.created_at ? new Date(b[1].created_at) : new Date(0)
        return dateB - dateA
      })
  )
})

const viewed = computed(() => {
  if (!meta.value.show_viewed_properties) return {}
  if (mode === 'edit' || mode === 'preview') {
    return {
      'hotel-100579342': {
        data: {
          process: 'hotel',
          place_type: 'hotel',
          place_id: '100579342',
          currency_code: 'USD',
          language_code: 'en-US',
        package_id: '4a5c9770-f901-4b96-8673-ecdc5ee49102',
        travelers: '[{"type":"adult","age":18,"room":1}]',
        expectation:
          '{"ht_des_code":"100579342","ht_checkin_date":"2025-04-10","ht_checkout_date":"2025-04-17","ht_des_type":"property_id","is_separate":false}',
          access_token: ''
        },
        key: 'hotel-100579342',
        created_at: 1743434370631,
        payload: {
          name: 'HANZ Gia Hoang Hotel Dalat',
        dates: 'Thu, Apr 10 - Thu, Apr 17',
        travelers: 1,
        rooms: 1,
        avatar:
          'https://i.travelapi.com/lodging/101000000/100580000/100579400/100579342/3226cb7a_b.jpg',
          star: 2.5,
          address: '306B Hai Ba Trung, Dalat City, Da Lat, 66000, VN',
          nationality: null,
          min_date: '2025-04-10'
        }
      }
    }
  }
  const items = { ...package_store.recent.viewed }

  Object.entries(items).forEach(([key, value]) => {
    const minDate = value?.payload?.min_date
      ? new Date(value.payload.min_date)
      : null
    const today = new Date()
    if (minDate && minDate < today) {
      remove_viewed(key)
    }
  })

  return Object.fromEntries(
    Object.entries(items)
      .filter((a) => {
        const minDate = a[1]?.payload?.min_date
          ? new Date(a[1].payload.min_date)
          : null
        const today = new Date()
        return !minDate || minDate >= today
      })
      .sort((a, b) => {
        const dateA = a[1]?.created_at ? new Date(a[1].created_at) : new Date(0)
        const dateB = b[1]?.created_at ? new Date(b[1].created_at) : new Date(0)
        return dateB - dateA
      })
  )
})

const remove_selected = (key) => {
  package_store.remove_selected(key)
}

const remove_viewed = (key) => {
  package_store.remove_viewed(key)
}

const handle_click_package = (data) => {
  if (auth_store.user.access_token) {
    data.access_token = auth_store.user.access_token
  }
  const booking_app_base_domain = context_store.app.booking_app_base_domain
  const qs = new URLSearchParams(data).toString()
  if (data.process === 'hotel' && data.place_type === 'hotel') {
    location.href = `https://${booking_app_base_domain}/shopping/products/hotel/${data.place_id}?${qs}&office_domain=${location.hostname}&scope_type=${context_store?.group}`
    return
  }
  location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}&office_domain=${location.hostname}&scope_type=${context_store?.group}`
}
</script>
