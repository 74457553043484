import {reactive, ref} from 'vue'


export default {
  id: 'editor',
  setup: () => {
    const header = reactive({
      el: null
    })

    const mouse_catcher = reactive({
      el: null
    })

    const left_menu = reactive({
      expanded: false,
      tab: null,
      block_category_id: null
    })

    const history = ref([])

    const updating_template = reactive({
      in_progress: false,
      error: null,
      message: null
    })

    const publishing = reactive({
      in_progress: false,
      error: null,
      message: null
    })

    const reverting_from_history = reactive({
      in_progress: false,
      index: 0
    })

    const mousedown = reactive({
      value: false,
    })

    const editing_texts = reactive({
      value: false,
      node: null,
    })

    const dragging = reactive({
      value: false,
      node: null,
    })

    const adding_layouts = reactive({
      value: false,
      block: null,
      nodes: [],
    })

    const adding_elements = reactive({
      value: false,
      block: null,
      nodes: [],
    })

    const setting_page = reactive({
      value: false,
    })

    const hovered_node = ref(null)
    const selected_layout_node = ref(null)
    const hovered_layout_node = ref(null)
    const selected_node = ref(null)
    const events = ref([])
    const bulk_commands = ref([])

    const autosave_interval = ref(null)

    return {
      events,
      bulk_commands,
      autosave_interval,
      header,
      left_menu,
      history,
      reverting_from_history,
      mouse_catcher,
      mousedown,
      editing_texts,
      dragging,
      adding_layouts,
      adding_elements,
      setting_page,
      hovered_node,
      selected_layout_node,
      hovered_layout_node,
      selected_node,
      updating_template,
      publishing
    }
  }
}
