<template>
  <div class="wheel-time-picker">
    <div
      :style="{
        height: `${container_height}px`,
        opacity: opacity
      }"
      class="container"
    >
      <ul ref="hour_items_cont_ref" class="items" @scroll="on_scroll('hour')">
        <li
          v-for="(item, index) in hour_items"
          :key="item.key"
          :ref="(el) => (hours_items_refs[index] = el)"
          :data-value="item.value"
          :style="get_item_style(item.value, 'hour')"
          class="item"
          @click="handle_clicks_to_item(index, 'hour')"
        >
          <div>{{ item.label }}</div>
        </li>
      </ul>
      <div class="" style="width: 6px"></div>
      <ul
        ref="minute_items_cont_ref"
        class="items"
        @scroll="on_scroll('minute')"
      >
        <li
          v-for="(item, index) in minute_items"
          :key="item.value"
          :ref="(el) => (minute_items_refs[index] = el)"
          :data-value="item.value"
          :style="get_item_style(item.value, 'minute')"
          class="item"
          @click="handle_clicks_to_item(index, 'minute')"
        >
          <div>{{ item.label }}</div>
        </li>
      </ul>
    </div>
    <div class="q-pa-sm flex justify-end">
      <q-btn
        color="primary"
        flat
        outline
        size="md"
        @click="handle_submit(`${hour_selected}:${minute_selected}`)"
      >
        ok
      </q-btn>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref } from 'vue'

const props = defineProps({
  modelValue: { type: String }
})
const emit = defineEmits(['update:modelValue'])

const opacity = ref(0)

const items_gen = (delta, total) =>
  [...Array(total).keys()].map((i) => ({
    key: String(i === 0 ? delta : i + delta).padStart(2, '0'),
    value: i === 0 ? '00' : `${i}`.padStart(2, '0'),
    label: i === 0 ? '00' : `${i}`.padStart(2, '0')
  }))
const hour_items = [
  ...items_gen(111, 24),
  ...items_gen(0, 24),
  ...items_gen(222, 24)
]

const minute_items = [
  ...items_gen(111, 60),
  ...items_gen(0, 60),
  ...items_gen(222, 60)
]

const container_height = 210
const item_height = 32

const hour_items_cont_ref = ref(null)
const minute_items_cont_ref = ref(null)

const hour_selected = ref(props.hourValue ?? 0)
const minute_selected = ref(props.minuteValue ?? 0)

const get_item_style = (value, type) => {
  let selected = minute_selected.value
  if (type === 'hour') selected = hour_selected.value
  return {
    height: `${item_height}px`,
    lineHeight: `${item_height}px`,
    fontSize: value === selected ? '18px' : '16px',
    fontWeight: value === selected ? 'bold' : 'normal',
    color: value === selected ? 'var(--q-primary)' : 'black'
  }
}

const hours_items_refs = ref([])
const minute_items_refs = ref([])

const on_scroll = (type) => {
  if (type === 'hour') {
    hours_items_refs.value.forEach((item) => {
      if (item) {
        const containerTop =
          hour_items_cont_ref.value.getBoundingClientRect().top
        const itemTop = item.getBoundingClientRect().top
        const relativeTop = itemTop - containerTop

        if (relativeTop >= 80 && relativeTop <= 90) {
          if (item.dataset.value) hour_selected.value = item.dataset.value
        }
      }
    })
  }
  if (type === 'minute') {
    minute_items_refs.value.forEach((item) => {
      if (item) {
        const containerTop =
          minute_items_cont_ref.value.getBoundingClientRect().top
        const itemTop = item.getBoundingClientRect().top
        const relativeTop = itemTop - containerTop

        if (relativeTop >= 80 && relativeTop <= 90) {
          if (item.dataset.value) minute_selected.value = item.dataset.value
        }
      }
    })
  }
}

const handle_clicks_to_item = (index, type) => {
  if (type === 'hour') {
    hours_items_refs.value[index].scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    })
  }
  if (type === 'minute') {
    minute_items_refs.value[index].scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    })
  }
}

const handle_submit = (val) => {
  emit('update:modelValue', val)
}

onMounted(() => {
  let [hours, minutes] = ['00', '00']
  if (props.modelValue) {
    [hours, minutes] = props.modelValue?.split?.(':')
  }
  hour_selected.value = hours.padStart(2, '0')
  setTimeout(() => {
    const hour_itemsIndex = hour_items.findIndex(
      (x) => x.key === hour_selected.value
    )

    if (hours_items_refs.value[hour_itemsIndex]) {
      hours_items_refs.value[hour_itemsIndex].scrollIntoView({
        block: 'center'
      })
    }

    minute_selected.value = minutes.padStart(2, '0')
    const minute_itemsIndex = minute_items.findIndex(
      (x) => x.key === minute_selected.value
    )

    if (minute_items_refs.value[minute_itemsIndex]) {
      minute_items_refs.value[minute_itemsIndex].scrollIntoView({
        block: 'center'
      })
    }
    opacity.value = 1
  }, 200)
})
</script>

<style lang="scss" scoped>
.wheel-time-picker {
  background: white;
  .container {
    display: flex;
    justify-content: center;
    width: 120px;
    transition: 0.5s;
    background: white;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      black 50%,
      transparent 100%
    );
    mask-image: linear-gradient(
      to bottom,
      transparent 0%,
      black 50%,
      transparent 100%
    );
    position: relative;
  }

  .container::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 100%;
    height: 32px;

    background: rgb(234, 236, 238);
    border-radius: 5px;
    pointer-events: none;
    z-index: -1;
  }

  .container::before {
    position: absolute;
    content: ':';
    top: 50%;
    left: 45%;
    transform: translate(2%, -50%);
    right: 0;
    width: 100%;
    height: 32px;

    background: transparent;
    pointer-events: none;
    z-index: 2;
    color: var(--q-primary);
    font-size: 20px;
    font-weight: bold;
  }

  .items {
    /* width: 100%; */
    height: 100%;
    padding: 74% 0;
    margin: 0;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .items::-webkit-scrollbar {
    display: none;
  }

  .item {
    padding: 0 10px;
    list-style-type: none;
    width: 100%;
    text-align: right;
    scroll-snap-align: center;
    font-size: 16px;
    user-select: none;
  }

  .item div {
    display: inline-block;
  }
}
</style>
