<template>
  <template
    v-if="
      [
        PACKAGE_FORM_POPUP_EVENT_HANDLER.id,
        TRIPS_FORM_POPUP_EVENT_HANDLER.id
      ].includes(meta.events?.click?.handler)
    "
  >
    <q-dialog
      :modelValue="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      persistent
    >
      <q-card style="max-width: 99vw; width: 1000px">
        <q-item>
          <q-item-section>
            <q-item-label
              v-if="meta.show_title || meta.show_caption"
              class="text-h6"
            >
              <span v-if="meta.show_title" v-html="meta.title?.[lang]" />
              <!-- <span v-if="meta.show_title && meta.show_caption">-</span> -->
              <!-- <span v-if="meta.show_caption">{{ meta.caption?.[lang] }}</span> -->
            </q-item-label>
            <q-item-label
              v-else-if="meta.events.click.destination"
              class="text-h6"
            >
              <span
                v-if="
                  meta.events?.click?.handler ===
                  TRIPS_FORM_POPUP_EVENT_HANDLER.id
                "
              >
                Trips -
              </span>
              <span
                class="text-capitalize"
                v-else-if="meta.events.click.product_combine"
              >
                {{ meta.events.click.product_combine }} -
              </span>
              <span>{{ meta.events.click.destination.name }}</span>
            </q-item-label>
            <q-item-label v-else class="text-h6">Search</q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn flat round dense icon="close" v-close-popup />
          </q-item-section>
        </q-item>
        <q-separator />
        <q-item
          v-if="
            meta.events?.click?.handler === PACKAGE_FORM_POPUP_EVENT_HANDLER.id
          "
          class="full-width"
        >
          <package-form
            v-if="modelValue"
            :default_destination="meta.events.click.destination"
            class="full-width"
            :packages="packages"
            :node="node"
            :color="meta.events.click.package_form_color"
            :products="products"
            :handle_submit="handle_submit"
            :product_combine="meta.events.click.product_combine"
          />
        </q-item>
        <q-item
          v-if="
            meta.events?.click?.handler === TRIPS_FORM_POPUP_EVENT_HANDLER.id
          "
          class="full-width"
        >
          <trips-form
            v-if="modelValue"
            :default_destination="meta.events?.click?.destination"
            class="full-width"
            :node="node"
            :color="meta.events?.click?.package_form_color"
            @submit="handle_submit"
          />
        </q-item>
      </q-card>
    </q-dialog>
  </template>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import PackageForm from 'src/components/common/package-form'
import TripsForm from 'src/components/common/trip-form'
import { stores } from 'src/stores'
import keyBy from 'lodash.keyby'

import {
  PACKAGE_FORM_POPUP_EVENT_HANDLER,
  TRIPS_FORM_POPUP_EVENT_HANDLER
} from 'src/constants'

const props = defineProps({
  node: { type: Object, required: true },
  modelValue: { type: Boolean, default: false }
})

const auth_store = stores.use_auth()
const context_store = stores.use_context()

const meta = computed(() => props.node.meta)
const lang = computed(() => context_store.language.code)

const products = keyBy(context_store.products, 'code')
const packages = keyBy(
  context_store.packages.map((x) => ({
    ...x,
    product_codes: x?.product_codes?.join('--')
  })),
  'product_codes'
)

const handle_submit = (data) => {
  if (data) {
    if (auth_store.user.access_token) {
      data.access_token = auth_store.user.access_token
    }
    const booking_app_base_domain = context_store.app.booking_app_base_domain
    const qs = new URLSearchParams(data).toString()
    location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}`
  }
}
</script>
