<template>
  <div style="height: 100%;">
    <div v-if="value.search_ranking && value.search_ranking.enabled && get_message(value.search_ranking.message)"
        :style="`background-color: ${get_background_color(value.search_ranking)}`" class="eg-bundle-search-ranking" style="padding-bottom: 1.4rem">
        <q-item-label :style="`color: ${get_color(value.search_ranking)}`">
          {{ get_message(value.search_ranking.message) }}
        </q-item-label>
    </div>
    <q-card :provider_id="provider_id" style="height: 100%; position: relative" class="column eg-rounded">
      <q-carousel
        transition-prev="slide-right"
        transition-next="slide-left"
        swipeable
        infinite
        :arrows="value.images.length > 1"
        animated
        control-text-color="white"
        style="height: 280px;"
        v-model="avatar_value"
        class="eg-rounded"
      >
        <q-carousel-slide
          :name="i"
          :key="i"
          v-for="(avatar, i) in avatars"
          style="padding: 0; background-color: #E1E1E1"
        >
          <q-img :ratio="5 / 3" height="100%" :src="avatar" />
        </q-carousel-slide>
      </q-carousel>
      <div  class="row category-absolute" v-if="value.categories && value.categories.length">
        <q-chip class="multileg-category" :key="category.id" square size="sm" v-for="category in value.categories">
          {{ get_label_category(category) }}
        </q-chip>
      </div>
      <div class="row text-capitalize q-py-md q-px-md">
        <div class="col-12">
          <div class="flex">
            <q-item-section>
              <q-item-label class="text-subtitle1 text-weight-medium">
                {{ displayed_title }}
              </q-item-label>
            </q-item-section>
            <q-item-section  top avatar
                             @click="$refs.itinerary_dialog.toggle()">
              <q-chip square size="sm text-black bg-blue"
                      @click="$refs.itinerary_dialog.toggle()"
                      style="background-color: #FFCFB4; cursor: pointer">
                      {{`${value.days} ${$t('common.days')}`}}
              </q-chip>
            </q-item-section>
          </div>
        </div>
        <div class="col-12">
          <div class="flex">
            <q-item-label class="flex items-center" caption>
              <template v-for="(city, index) in value.cities">
                <div v-if="index > 0" class="dot"></div>
                <span>{{ get_city_from_airport(city.place) }}</span>
              </template>
            </q-item-label>
          </div>
          <q-separator class="q-my-sm" />
        </div>
        <div class="col-12 row">
          <li v-if="flight_type" class="col-6 text-black" style="font-size: 0.75rem;">
            {{ flight_type }}
          </li>
          <li v-if="hotel_stars" class="col-6 text-black" style="font-size: 0.75rem;" dom-key="common.stars.hotel">
            {{ hotel_stars }}
          </li>
          <li v-if="have_transfer" class="col-6 text-black" style="font-size: 0.75rem;">
            {{ have_transfer }}
          </li>
          <li v-if="activities" class="col-6 text-black" style="font-size: 0.75rem;">
            {{ $t('common.activities') }}
          </li>
        </div>
        <div class="col-12 q-mt-xs row q-col-gutter-xs">
          <div class="full-width" v-for="highlight in highlights">
            <div v-if="highlight.value" class="row items-start">
              <q-item-section side class="q-pr-sm">
                <q-icon name="verified" color="black" size="12px" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-caption text-black" v-html="highlight.value" />
              </q-item-section>
            </div>
          </div>
        </div>
      </div>
      <q-item style="margin-top: auto;" class="q-pb-md">
        <q-item-section>
          <q-btn flat bordered style="background-color: #F4F5F8; border: 1px solid #E1E1E1" @click="select">
            <span dom-key="common.select" class="text-primary text-bold">
              {{ $t('common.select') }}
              <q-icon size="14px" name="arrow_forward" />
            </span>
          </q-btn>
        </q-item-section>
      </q-item>
    </q-card>
  </div>
</template>
<script>
import {
  convert_string_to_date,
  get_city_from_airport,
  safely_parse_json
} from 'src/composables/utils'

export default {
  props: {
    value: { type: Object, required: true },
    language_code: { type: String, required: true },
  },
  data() {
    return {
      avatar_value: 0,
    }
  },
  computed: {
    avatars() {
      const images = this.value.images || []
      if (!images.length) return []
      return images
    },
    start_date() {
      const { mh_start_date } = safely_parse_json(this.$route.query.expectation) || {}
      return convert_string_to_date(mh_start_date, 'iso')
    },
    provider_id() {
      return this.value ? this.value.provider || this.value.id : ''
    },
    displayed_title() {
      const trip_translations = this.value?.trip_translations
      return trip_translations?.find?.(
        el =>
          el.key === 'package_name' &&
          el.language_code === this.language_code,
      )?.value
    },
    cities () {
      return this.value.cities
    },
    flight_type () {
      if (!this.value.flight  || !this.value.flight.type || !this.value.flight.total ) {
        return ''
      }
      const is_round_trip = this.value.flight.type === 'RoundTrip'
      if (is_round_trip) {
        return `${this.$t('common.round-trip-flight')}`
      } else if (this.value.flight.total) {
        return `${this.value.flight.total} ${this.$t('common.flights')}`
      } else {
        return ''
      }
    },
    hotel_stars () {
      if (!this.value.hotel) {
        return ''
      }
      return this.$t('common.stars.hotel')
    },
    have_transfer () {
      return this.value.transfers ? `${this.$t('common.airport.transfers')}` : ''
    },
    activities () {
      return this.value.activities
    },
    highlights () {
     return this.value?.highlight_translations
        ?.filter?.(item =>
          item.some(
            i =>
              i.language_code === this.language_code &&
              i.key === 'content',
          ),
        )
        ?.reduce((acc, item) => {
          acc.push(...item)
          return acc
        }, [])
    }
  },
  methods: {
    select() {
      this.$emit('select', this.value)
    },
    get_city_from_airport,
    get_label_category (category) {
      const item = this.value.categories.find((item) => {
        return item.id === category.id
      })
      const translation = item.category_translations.find((t) => {
        return t.language_code === this.language_code
      })
      return translation?.value || ''
    },
    get_message (message) {
      if (!message) return ''
      const default_lang = Object.keys(message || {})?.[0] || ''
      return message[this.language_code] || message[default_lang] ||  ''
    },
    get_color (search_ranking) {
      if (!search_ranking || !search_ranking?.text_color) return '#fff !important'
      return search_ranking?.text_color
    },
    get_background_color (search_ranking) {
      if (!search_ranking || !search_ranking?.background_color) return '#4caf50 !important'
      return search_ranking?.background_color
    }
  },
}
</script>
<style lang="scss" scoped>
.dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0px 8px;
  background-color: rgba(0,0,0,0.54);
}
.category-absolute {
  position: absolute;
  top: 240px;
  left: 10px;
}
.multileg-category {
  background-color: #D1DDFF;
  color: #1A3996;
}
.eg-hotel-search-ranking {
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  margin-bottom: -0.6rem;
  padding: 1.2rem 0.7rem 1.4rem 0.7rem;
  padding-top: 0.7rem
}
</style>

