<template>
  <div v-bind="node_bind" class="q-row egs-buttons">
    <q-btn v-bind="btn_bind"
           @click="click"
           :loading="loading"
           no-caps>
    </q-btn>
    <click-event v-model="dialog_showed" :node="props.node" />

  </div>
</template>
<script setup>
import {computed, defineProps, ref} from 'vue'
import {
  EMBED_MODE,
  LINK_REDIRECT_EVENT_HANDLER,
  NODE_BASE_PROPS,
  PAGE_REDIRECT_EVENT_HANDLER, 
  PUBLISH_MODE,
  DEFAULT_BUTTON_DESIGN, 
  TEXT_BUTTON_DESIGN,
  PACKAGE_FORM_POPUP_EVENT_HANDLER,
  TRIPS_FORM_POPUP_EVENT_HANDLER
} from 'src/constants'

import {stores} from 'src/stores'
import {change_page} from 'src/composables/editor'
import {useRouter} from 'vue-router'
import ClickEvent from 'src/components/common/click-event/index.vue'


const props = defineProps(NODE_BASE_PROPS)
const router = useRouter()
const meta = computed(() => props.node.meta)

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const loading = ref(false)
const dialog_showed = ref(false)


const click = async () => {
  const click_event = meta.value.events?.click
  if (!click_event) return
  if (
    [
      PACKAGE_FORM_POPUP_EVENT_HANDLER.id,
      TRIPS_FORM_POPUP_EVENT_HANDLER.id
    ].includes(click_event.handler)
  ) {
    dialog_showed.value = true
  } else if (click_event.handler === PAGE_REDIRECT_EVENT_HANDLER.id) {
    const endpoint = click_event.page_endpoint
    if (context_store.mode === PUBLISH_MODE) {
      router.push({ path: endpoint })
    } else if (context_store.mode === EMBED_MODE) {
      window.location.href = endpoint
    } else {
      const page = canvas_store.pages.find((p) => p.endpoint === endpoint)
      if (!page) return
      change_page(page)
    }
  } else if (click_event.handler === LINK_REDIRECT_EVENT_HANDLER.id) {
    window.open(click_event.link, '_blank')
  }
}

const parent = computed(
  () => canvas_store.flattened_nodes[props.node.parent_id]
)

const node_styles = computed(() => {
  let result = {...props.styles}

  if (parent.value.layout.columns.enable) {
    result['width'] = '100%'
  }
  else {
    result['width'] = `${props.node.dimension.width}px`
  }

  return result
})

const node_bind = computed(() => {

  let result = {
    'style': node_styles.value,
  }

  return result
})

const btn_styles = computed(() => {
  let result = {
    'width': '100%',
  }

  const border_radius = props.styles['border-radius']
  if (border_radius) {
    result['border-radius'] = border_radius
  }

  const meta = props.node.meta

  if (meta.color && meta.design !== TEXT_BUTTON_DESIGN) result['background-color'] = meta.color
  if (meta.text_color) result['color'] = meta.text_color

  return result
})


const btn_bind = computed(() => {
  let result = {
    'style': btn_styles.value
  }

  const meta = props.node.meta || {}

  if (meta.label_enabled) {
    result['label'] = meta.label[context_store.language.code]
  }

  if (meta.design && meta.design !== DEFAULT_BUTTON_DESIGN) {
    result[meta.design] = true
  }

  if (meta.size) {
    result['size'] = meta.size
  }
  if (meta.prepend_icon) {
    result['icon'] = meta.prepend_icon
  }
  return result
})

</script>
