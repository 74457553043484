import { ref } from 'vue'
import { EMBED_MODE } from 'src/constants'

export const init_canvas = (mode) => ({
  id: 'canvas',
  setup: {
    state: () => {
      const pages = ref([])
      const page = ref(null)
      const nodes = ref({})
      const nested_nodes = ref([])
      const flattened_nodes = ref({})
      const node = ref(null)
      const el = ref(null)

      const screen_width = ref(0)
      const layout_nodes = ref({})

      const page_rect = ref({
        top: 0,
        left: 0,
        width: 0,
        height: 0
      })

      const submitting_forms = ref({})
      const carry_nodes = ref({})

      return {
        nodes,
        nested_nodes,
        flattened_nodes,
        el,
        screen_width,
        page_rect,
        pages,
        page,
        node,
        submitting_forms,
        layout_nodes,
        carry_nodes
      }
    },
    persist:
      mode === EMBED_MODE
      && {
        paths: [
          'nodes',
          'nested_nodes',
          'flattened_nodes',
          'el',
          'screen_width',
          'page_rect',
          'pages',
          'page',
          'node',
          'submitting_forms',
          'layout_nodes',
          'carry_nodes'
        ]
      }
  }
})
