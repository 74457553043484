export const AUH = {
  'id': '4740417',
  'created_at': '2024-08-02T12:34:52.328573',
  'updated_at': '2024-10-03T08:17:05.068574',
  'deleted': false,
  'name': 'Abu Dhabi (AUH-Abu Dhabi Intl.)',
  'long_name': 'Abu Dhabi, United Arab Emirates (AUH-Abu Dhabi Intl.)',
  'type': 'airport',
  'duffel_id': 'arp_auh_ae',
  'google_id': null,
  'expedia_id': '4740417',
  'viator_id': null,
  'goquo_id': null,
  'code': 'AUH',
  'popular_score': 0,
  'location': {
    'point': {
      'type': 'Point',
      'coordinates': [
        54.646174,
        24.427109
      ]
    },
    'polygon': null,
    'country_code': 'AE',
    'city_code': 'AUH'
  },
  'timezone': {
    'name': 'Asia/Dubai',
    'gmt': 4
  },
  'ancestors': [
    {
      'id': '453',
      'type': 'city',
      'code': null
    },
    {
      'id': '6053838',
      'type': 'province_state',
      'code': null
    },
    {
      'id': '189',
      'type': 'country',
      'code': null
    },
    {
      'id': '6023182',
      'type': 'continent',
      'code': null
    }
  ],
  'descendants': null,
  'associations': null,
  'nearest_airports': [],
  'metadata': {}
}