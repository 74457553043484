<template>
  <div v-if="meta.destination">
    <div class="row q-col-gutter-md">
      <div class="col-12 q-pt-xs text-h6 text-weight-medium">
        <div class="row">
          <q-item-section>
            <q-item-label class="text-h6 text-weight-medium" lines="1">
              <span dom-key="components.products.multileg.list.holiday-in">
                {{
                  meta.destination.name
                    ? $t('components.products.hotel.holiday-in')
                    : $t('components.products.hotel.hotel', 'Hotel')
                }}
              </span>
              {{ meta.destination.name }}
            </q-item-label>
          </q-item-section>
        </div>
      </div>
      <div class="col-12 q-pt-xs">
        <div class="row">
          <q-item-section>
            <q-item-label>
              <span class="text-capitalize">{{ $t('common.showing') }}</span>
              <span>{{ ` 1- ` }}</span>
              <span>
                {{ per_page }}
              </span>
              <span>{{ ` ${$t('common.hotel')}` }}</span>
            </q-item-label>
          </q-item-section>
        </div>
      </div>
    </div>
    <div class="col-12 q-pt-xs">
      <q-separator />
    </div>
    <div class="col-12 q-pt-xs q-pb-md">
      <div class="row q-col-gutter-sm items-center">
        <div class="col-12 col-md-9">
          <div class="row full-width items-center">
            <div class="text-caption text-black col-md-9">
              <div dom-key="common.local-time-desc">
                {{ $t('common.local-time-desc') }}
              </div>
              <div dom-key="common.taxes-and-fees-included">
                {{ $t('common.taxes-and-fees-included') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="['row', `q-col-gutter-${meta.items_gap ?? 'md'}`]">
      <div v-for="(record, i) in records" :key="i" :class="get_class">
        <item :value="record" @select="on_select" />
      </div>
      <div v-if="can_load_more" class="col-12 text-center">
        <q-btn
          class="text-capitalize"
          color="primary"
          icon-right="arrow_drop_down"
          no-caps
          outline
          rounded
          @click="load_more"
        >
          <span dom-key="more-options">
            {{ $t('common.more-options') }}
          </span>
        </q-btn>
      </div>
    </div>
  </div>
  <q-dialog v-model="dialog_search">
    <q-card style="width: 1000px; max-width: 1000px">
      <q-item>
        <q-item-section>
          <q-item-label
            class="text-h6 text-capitalize"
            dom-key="common.hotel-question-dialog"
          >
            {{ $t('common.hotel-question-dialog') }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn dense flat icon="close" round @click="dialog_search = false" />
        </q-item-section>
      </q-item>
      <q-separator />
      <q-card-section>
        <package-form
          v-if="item_selected"
          :node="node"
          :default_destination="item_selected"
          :handle_submit="handle_submit"
          :packages="packages"
          :products="products"
          class="full-width"
          product_combine="hotel"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { use_services } from 'src/composables/services'
import { stores } from 'src/stores'
import Item from 'src/components/common/package-grid/components/card/index.vue'
import PackageForm from 'src/components/common/package-form/index.vue'
import key_by from 'lodash.keyby'

const props = defineProps({
  meta: { type: Object, required: true },
  node: { type: Object, required: true }
})

const context_store = stores.use_context()
const auth_store = stores.use_auth()

const currency_code = context_store.currency?.code
const language_code = context_store.language?.code

const item_selected = ref(null)
const dialog_search = ref(false)
const records = ref([])
const total = ref(null)
const services = use_services()
const items_loading = ref(false)
const can_load_more = ref(true)
const per_page = ref(props.meta.items_per_page[context_store.viewport] ?? 3)

const get_class = computed(() => {
  return `col-${12 / props.meta.columns[context_store.viewport] ?? '6'}`
})

const on_select = (item) => {
  item_selected.value = item
  dialog_search.value = true
}

const load_more = () => {
  per_page.value =
    Number(per_page.value) +
    Number(props.meta.items_per_page[context_store.viewport] ?? 3)
  fetch_item(per_page.value)
}

const handle_submit = (data) => {
  if (data) {
    if (auth_store.user.access_token) {
      data.access_token = auth_store.user.access_token
    }
    const booking_app_base_domain = context_store.app.booking_app_base_domain
    const qs = new URLSearchParams(data).toString()
    if(data.process === 'hotel' && data.place_type === 'hotel') {
      location.href = `https://${booking_app_base_domain}/shopping/products/hotel/${data.place_id}?${qs}&scope_type=${context_store?.group}`
      return;
    }
    location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}&scope_type=${context_store?.group}`
  }
}

const products = key_by(context_store.products, 'code')
const packages = key_by(
  context_store.packages.map((x) => ({
    ...x,
    product_codes: x?.product_codes?.join('--')
  })),
  'product_codes'
)

const fetch_item = async (items_per_page) => {
  items_loading.value = true
  const response = await services.AppService.list_properties({
    place_id: props.destination?.id,
    currency: currency_code,
    language_code: language_code,
    per_page: items_per_page ?? per_page.value,
    page: 1,
    longitude: props.meta.destination?.location?.point?.coordinates?.[0],
    latitude: props.meta.destination?.location?.point?.coordinates?.[1],
    r: '200'
  })
  if (response.data.result.length) {
    if (records.value.length) {
      records.value = [...records.value, ...response.data.result]
    } else {
      records.value = response.data.result
    }
  } else {
    can_load_more.value = false
  }
  items_loading.value = false
}

watch(
  () => props.meta.items,
  (items) => {
    can_load_more.value = false
    records.value = items
  },
  { deep: true }
)

onMounted(async () => {
  if (!props.meta.items?.length) {
    await fetch_item()
    can_load_more.value = true
  } else {
    records.value = props.meta.items
    total.value = props.meta.items?.length ?? 1
    can_load_more.value = false
  }
})
</script>
