<template>
  <q-card class="eg-rounded q-pb-md">
    <q-skeleton type="rect" style="height: 232px" />
    <q-item dense style="margin-top: -30px" class="q-mb-xs">
      <q-item-section>
      </q-item-section>
    </q-item>
    <q-item dense>
      <q-item-section>
        <div class="q-col-gutter-sm">
          <div v-for="n in 1" :key="n">
            <q-skeleton type="rect" style="height: 24px; width: 200px" />
          </div>
          <div>
            <div class="row q-gutter-sm">
              <q-skeleton type="rect" style="height: 24px; width: 50px" />
              <q-skeleton type="rect" style="height: 24px; width: 50px" />
              <q-skeleton type="rect" style="height: 24px; width: 50px" />
            </div>
          </div>
        </div>
      </q-item-section>
    </q-item>
    <q-item dense class="q-pt-xs">
      <q-item-section>
        <q-skeleton type="rect" style="height: 55px; width: 95%" />
      </q-item-section>
    </q-item>
    <q-item dense class="q-pt-xs">
      <q-item-section>
        <q-skeleton type="rect" style="height: 79px; width: 90%" />
      </q-item-section>
    </q-item>
    <q-separator class="q-my-md" />
    <q-item dense>
      <q-item-section>
        <q-skeleton type="rect" style="width: 70px; height: 30px" />
      </q-item-section>
      <q-item-section side>
        <q-skeleton type="rect" style="width: 90px; height: 30px" />
      </q-item-section>
    </q-item>
  </q-card>
</template>
<script setup lang="ts">
</script>
