<template>
  <form-builder
    v-if="payload"
    :key="update_key"
    :fields="meta.fields"
    :style="{ '--q-primary': color }"
    :value="payload"
    product_name="trip"
    @submit="submit"
  />
</template>
<script setup>
import { defineEmits, onMounted, ref } from 'vue'
import { stores } from 'src/stores'
import FormBuilder from 'src/components/common/form-builder'
import { get_meta_default } from './meta_default'

const props = defineProps({
  trip: { type: Object, required: true },
  color: { type: String },
  mh_code: { type: String, required: true },
  mh_id: { type: String, required: true }
})

const context_store = stores.use_context()
const payload = ref({
  place: null,
  travelers: [],
  dates: null,
  cities: props.trip.cities,
  cabin_class: 'Economy'
})
const update_key = ref(0)

const emit = defineEmits(['submit'])

onMounted(() => {
  const default_values = props.setting?.default_values ?? {}
  if (default_values?.place || default_values?.place === null) {
    payload.value.place = default_values.place
  }
  if (default_values?.dates) {
    payload.value.dates = default_values.dates
  }
  if (default_values?.travelers) {
    payload.value.travelers = default_values.travelers
  }
  update_key.value += 1
})

const traveler_limits = [
  {
    adult: {
      traveler_type: 'adult',
      limit: 9
    },
    child: {
      traveler_type: 'child',
      limit: 6
    },
    infant: {
      traveler_type: 'infant',
      limit: 6
    }
  }
]

const rules = {
  dates: [(val) => !!val || 'Required'],
  place: [(val) => !!val || 'Required'],
  cabin_class: [(val) => !!val || 'Required'],
  travelers: []
}

const trip_package = {
  booking_cutoff_days: 6,
  total_traveler_limit: 9,
  traveler_limit_type: 'per_type'
}

const traveler_types = ['adult', 'infant', 'child']
const place_types = [
  'province_state',
  'multi_city_vicinity',
  'airport',
  'city',
  'country'
]

const meta = get_meta_default({
  props,
  rules,
  payload,
  place_types,
  traveler_limits,
  traveler_types,
  trip_package,
  cabin_classes: {}
})


const submit = () => {
  const { travelers, place, dates, cabin_class, cities } = payload.value || {}

  const custom_duration = cities.reduce((acc, item) => {
    acc[item.place.code] = item.days; // Gán giá trị vào object kết quả
    return acc;
  }, {});

  emit('submit', {
    process: 'multileg_holiday',
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    travelers: JSON.stringify(travelers),
    expectation: JSON.stringify({
      is_separate: false,
      mh_des_code: props.mh_id,
      mh_des_type: 'place_id',
      mh_start_date: dates,
      mh_departure_code: place?.code,
      mh_departure_type: 'place_id',
      fl_cabin_class: cabin_class
    }),
    product_code: 'multileg_holiday',
    place_code: props.mh_code,
    product_cd: 'multileg_holiday',
    custom_duration: JSON.stringify(custom_duration),
  })
}
</script>
