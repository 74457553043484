import Api from '../../composables/api'

export default class IamService extends Api {
  get_credentials(payload) {
    return this.do_request(this.BASE_URL + '/auth', {
      method: 'get',
      payload
    })
  }
  authenticate(payload) {
    return this.do_request(this.BASE_URL + '/auth', {
      method: 'post',
      payload
    })
  }
  accounts(access_token) {
    return this.do_request(
      this.BASE_URL + '/auth?access_token=' + access_token,
      {
        method: 'get'
      }
    )
  }

  forget_password_with_token(payload) {
    return this.do_request(this.BASE_URL + '/auth/forget-password', {
      method: 'post',
      payload
    })
  }

  change_password_with_token(payload) {
    return this.do_request(
      this.BASE_URL + '/auth/forget-password/change-password',
      {
        method: 'post',
        payload
      }
    )
  }
}
